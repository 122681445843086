import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { HOSTNAME, googleLogin } from '../../action/request';
import UserContext from '../../context/user/user';
import { Ss, ls } from '../../constant';
import { ITEMS, SNS_SIGNUP } from '../../route/constant';
import { gtagOnClickLogin } from '../../action/gTag';
import { RETURN_CODE } from '../../action/constant';
import { isMotilink, isWelcomeModal } from '../../atom/atom';
import { useRecoilValue } from 'recoil';
import { useCookies } from 'react-cookie';
import { motilinkRedirect } from '../../util/useSocialLoginPlanet';

function GoogleCallback() {
  const [cookies] = useCookies(['autoLogin']);
  const isWelcomeModalValue = useRecoilValue(isWelcomeModal);
  const isMotilinkValue = useRecoilValue(isMotilink);
  const navigate = useNavigate();
  const location = useLocation();
  const loginPath = localStorage.getItem('loginPath');

  const [token, setToken] = useState();
  const { action: userAction, state: userState } = useContext(UserContext);
  const AWS_URL = '43.203.118.174';
  const FAB365_URL = 'fab365.net';
  const FAB365_URL_MOCK = 'java.fab365.net';
  const FAB365_DEV_URL = 'dev.fab365.net';
  const FAB365_DEV_URL_MOCK = 'dev-java.fab365.net';
  const FAB365_DEV_URL_MOCK_SECOND = 'dev2.fab365.net';
  const userAgent = navigator.userAgent.toLowerCase();

  let redirectBaseUrl;

  if (HOSTNAME === FAB365_URL) {
    redirectBaseUrl = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_URL;
  } else if (HOSTNAME === FAB365_URL_MOCK) {
    redirectBaseUrl = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_URL_MOCK;
  } else if (HOSTNAME === FAB365_DEV_URL) {
    redirectBaseUrl = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_DEVELOP_URL;
  } else if (HOSTNAME === FAB365_DEV_URL_MOCK) {
    redirectBaseUrl = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_DEVELOP_URL_MOCK;
  } else if (HOSTNAME === FAB365_DEV_URL_MOCK_SECOND) {
    redirectBaseUrl = process.env.REACT_APP_GOOGLE_LOGIN_REDIRECT_DEVELOP_URL_MOCK_SECOND;
  }

  // 인가코드로 토큰 요청
  const getToken = async () => {
    const urlParams = new URLSearchParams(location.search);
    const authorizationCode = urlParams.get('code');
    // 토큰 요청
    if (authorizationCode) {
      const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
      const clientSecret = process.env.REACT_APP_GOOGLE_SECRET;
      const redirectUri = redirectBaseUrl;
      const tokenUrl = 'https://oauth2.googleapis.com/token';

      try {
        const response = await fetch(tokenUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `code=${authorizationCode}&client_id=${clientId}&client_secret=${clientSecret}&redirect_uri=${redirectUri}&grant_type=authorization_code`,
        });
        const data = await response.json();
        // console.log('Access Token:', data.access_token);
        // console.log('ID Token:', data.id_token);
        setToken(data.id_token);
      } catch (error) {
        // console.error('Error exchanging code for token:', error);
        navigate('/');
      }
    }
  };

  const getUserInfo = async token => {
    try {
      if (token !== undefined) {
        const from = userState.isApp || isMotilinkValue ? 'M' : cookies.autoLogin ? 'A' : 'W';
        console.info('userAgent : ', userAgent, 'from :', from);
        console.info('isMotilinkValue :', isMotilinkValue);
        const { data: res } = await googleLogin(token, from);
        // console.log('res', res);
        // console.log('google get User 요청 완료');

        // block된 유저일 경우
        if (res.returnCode === RETURN_CODE.C00008) {
          alert(res.message);
          const url = isMotilinkValue ? '/appdesigner-login' : '/';
          return navigate(url);
        }

        const userInfo = res.data.userInfo;
        const userToken = res.data.token;
        const loginData = res.data;

        if (userInfo) {
          // token을 localStorage에 저장
          localStorage.setItem(ls.id, userInfo.id_uesr);
          localStorage.setItem(ls.accessToken, userToken.access_token);
          localStorage.setItem(ls.refreshToken, userToken.refresh_token);
          // userInfo context에 저장
          userAction.setEmail(userInfo.email);
          userAction.setId(userInfo.id_uesr);
          userAction.setIsLogin(true);
          userAction.setThumbnail(userInfo.thumbnail);
          userAction.setIsLogin(false);
          if (sessionStorage.getItem(Ss.itemNumber)) {
            navigate(`${ITEMS}/${sessionStorage.getItem(Ss.itemNumber)}`);
            sessionStorage.removeItem(Ss.itemNumber);
          }
          gtagOnClickLogin('Google');

          if (isMotilinkValue || loginPath?.includes('appdesigner')) {
            return motilinkRedirect({ loginData });
          } else {
            navigate('/');
          }

          // if (isWelcomeModalValue) {
          //   navigate('/');
          //   localStorage.removeItem(ls.loginPath);
          // } else {
          //   navigate(localStorage.getItem(ls.loginPath));
          //   localStorage.removeItem(ls.loginPath);
          // }

          // gtag 구글 로그인 추적 태그
        }

        // 회원가입 처리
        if (res.data.id_user === null) {
          // console.log('회원가입');
          // user context에 data 저장
          userAction.setSnsUserInfo(res.data);
          const url = isMotilinkValue ? `${SNS_SIGNUP}&app=ture` : `${SNS_SIGNUP}`;
          navigate(url);
        }
      }
    } catch (error) {
      console.error(error);
      alert('Request failed.');
      const url = isMotilinkValue ? '/appdesigner-login' : '/';
      navigate(url);
    }
  };

  useEffect(() => {
    getToken();
  }, [location.search, navigate]);

  useEffect(() => {
    if (token !== undefined) {
      getUserInfo(token);
    }
  }, [token]);

  return null;
}

export default GoogleCallback;
