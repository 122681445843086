import React, { useState, useEffect } from 'react';
import EeaPopup from './EeaPopup';
import EeaSetting from './EeaSetting';
import { isMobileAtom } from '../../atom/atom';
import { useRecoilState } from 'recoil';
import { eeaCookies } from '../../util/eeaCookies';
import { useCookies } from 'react-cookie';
import { useSearchParams } from 'react-router-dom';

const EeaModal = () => {
  const [settingModal, setSettingModal] = useState(false);
  const [cookiesPopup, setCookiesPopup] = useState('');
  const [isMobile, setIsMobile] = useRecoilState(isMobileAtom);
  const [cookies, setCookie] = useCookies(['eea_approval']);
  const [searchParams] = useSearchParams();
  // 반응형 감지
  useEffect(() => {
    const handleResize = () => {
      // 너비가 700px 이하인 경우를 모바일로 판단
      setIsMobile(window.innerWidth <= 700);
    };

    window.addEventListener('resize', handleResize);

    handleResize(); // 초기 렌더링 시 한 번 실행

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    if (cookies?.eea_approval) {
      window.gtag('consent', 'update', cookies?.eea_approval);
      setCookiesPopup(true);
    } else {
      window.gtag('consent', 'default', {
        ad_storage: 'denied',
        ad_user_data: 'granted',
        ad_personalization: 'denied',
        functionality_storage: 'denied',
        analytics_storage: 'denied',
      });
      setCookiesPopup(false);
    }
  }, []);

  useEffect(() => {
    if (cookiesPopup === false && settingModal === false) {
      document.body.style.overflow = 'unset';
    }
  }, [cookiesPopup, settingModal]);

  //eea 쿠키 전체동의
  //ad_user_data : 온라인 광고를 위해 사용자 데이터 전송 (필수)
  //ad_storage : 광고와 관련된 저장 (Targeting)
  //ad_personalization : 개인 맞춤 광고 동의 (Targeting)
  //functionality_storage: 기능 지원하는걸 저장 ( 언어 설정 등등, Functional)
  //analytics_storage: 방문횟수 (Performance)

  const onClickAllCookies = () => {
    const consents = {
      ad_storage: 'granted',
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      functionality_storage: 'granted',
      analytics_storage: 'granted',
    };

    eeaCookies(consents, setCookie);
    setCookiesPopup(true);
  };

  const onClickCloseEea = () => {
    setCookiesPopup(true);
    setSettingModal(false);
    document.body.style.overflow = 'unset';
  };

  return (
    <div>
      {!searchParams?.get('selectedTabID') && (
        <>
          {cookiesPopup || cookiesPopup === '' ? (
            <></>
          ) : (
            <EeaPopup
              setCookiesModal={setSettingModal}
              isMobile={isMobile}
              setCookiesPopup={setCookiesPopup}
              onClickAllCookies={onClickAllCookies}
              onClickCloseEea={onClickCloseEea}
            />
          )}

          {settingModal ? (
            <EeaSetting
              setCookiesModal={setSettingModal}
              isMobile={isMobile}
              cookiesModal={settingModal}
              setCookiesPopup={setCookiesPopup}
              onClickAllCookies={onClickAllCookies}
              onClickCloseEea={onClickCloseEea}
            />
          ) : (
            ''
          )}
        </>
      )}
    </div>
  );
};

export default EeaModal;
