import { useRef, useState, useEffect } from 'react';
import ManageUploadInput from '../../../mangemodel/common/ManageUploadInput';
import ManageDropBox from '../../../mangemodel/common/ManageDropBox';
import { ManageEditSection, FileBox } from './manageEditStyle';
import ImgDnd from '../../../mangemodel/editModel/ImgDnd';
import { uploadManageImg, uploadManageFile } from '../../../../action/request';
import PreviewImgView from '../../../mangemodel/editModel/PreviewImgView';
import ManageButton from '../../../mangemodel/common/ManageButton';
import { positiveValue, positiveValueWithDecimal } from '../../../section/util';
import ImageWrapper from './RequiredInfo/ImageWrapper';
import { getInputs } from './RequiredInfo/inputFields';
import { manageProcessingAtom } from '../../../../atom/atom';
import { useRecoilState } from 'recoil';
const RequiredInfo = ({
  calculateDiscountedPrice,
  setRequiredData,
  modelName,
  requiredData,
  priceUSD,
  discountRate,
  dropSelectValue,
  setDropSelectValue,
  detailImg,
  setDetailImg,
  onClickAddImg,
  coverImg,
  setCoverImg,
  coverImgHover,
  setCoverImgHover,
  ctgList,
  fileList,
  setFileList,
  requiredInfo,
  setDeleteFiles,
  deleteFiles,
  failed,
  modalIsOpen,
  setFailed,
  setPriceOption,
  priceOption,
  idItem,
}) => {
  const [, setProcessing] = useRecoilState(manageProcessingAtom);
  const [bigsizeImg, setBigSizeImg] = useState(false);
  const [zoomImg, setZoomImg] = useState();
  const [totalFileSize, setTotalFileSize] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const [index, setIndex] = useState(1);
  const fileRef = useRef(null);
  const requiredScroll = useRef(null);
  const isFree = priceOption?.name === 'Free';
  const isFresPlaceholder = isFree ? 'None' : 'Please input';
  const inputs = getInputs(
    isFresPlaceholder,
    isFree,
    priceUSD,
    discountRate,
    calculateDiscountedPrice
  );

  const priceOptionList = [
    { name: 'Paid', value: 'paid' },
    { name: 'Free', value: 'free' },
  ];

  const onChange = e => {
    const { value, name, type } = e.target;
    const nextInputs = {
      ...requiredData,
      [name]: value,
    };

    if (name === 'discountRate') {
      if (value === '' || (Number(value) >= 0 && Number(value) <= 100)) {
        setRequiredData(nextInputs);
      } else {
        alert('Enter a number between 0-100');
      }
    } else {
      if (type === 'number') {
        nextInputs[name] = positiveValue(value);
      }
      setRequiredData(nextInputs);
    }
  };

  // const handleFileChange = async e => {
  //   const files = Array.from(e.target.files).filter(file => file.size > 0);

  //   if (files.length === 0) return;

  //   const processFiles = (existingFiles, newFiles) => {
  //     const fileMap = new Map();

  //     existingFiles.forEach(file => {
  //       const fileName = file.file_name || file.name;
  //       if (fileName) {
  //         fileMap.set(fileName, file);
  //       }
  //     });

  //     newFiles.forEach(file => {
  //       const fileName = file.file_name || file.name;
  //       if (fileName) {
  //         fileMap.set(fileName, file);
  //       }
  //     });

  //     return Array.from(fileMap.values());
  //   };

  //   const updatedFileList = processFiles(fileList, files);
  //   setTotalFileSize(formatFileTotal(updatedFileList));
  //   setFileList([...updatedFileList]);

  //   const uploadedFiles = [];

  //   for (const file of files) {
  //     try {
  //       const { data: res } = await uploadManageFile({ file, idItem });
  //       uploadedFiles.push({
  //         file_name: file.name,
  //         file_url: res.data.url,
  //         file_size: Number(res.data.fileSize),
  //         date_upload: res.data.date_upload,
  //         isLoading: true,
  //       });
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }

  //   const finalFileList = processFiles(updatedFileList, uploadedFiles);
  //   setFileList(finalFileList);

  //   e.target.value = null;
  // };

  function getSecondLastPathAndAfter(url) {
    const parts = url?.split('/');

    return `/${parts[parts?.length - 2]}/${parts[parts?.length - 1]}`;
  }

  const handleFileChange = async e => {
    const files = Array.from(e.target.files).filter(file => file.size > 0);

    // const totalList = fileList?.length === 0 ? files : [...fileList, ...files];

    if (files.length === 0) return;

    const processFiles = (existingFiles, newFiles) => {
      const fileMap = new Map();

      existingFiles?.forEach(file => {
        const fileName = getSecondLastPathAndAfter(file?.file_url);

        if (fileName) {
          fileMap.set(fileName, file);
        }
      });

      newFiles?.forEach(file => {
        const fileName = getSecondLastPathAndAfter(file?.file_url);
        if (fileName) {
          fileMap.set(fileName, file);
        }
      });

      return Array.from(fileMap.values());
    };

    const uploadedFiles = [];

    for (const file of files) {
      try {
        const { data: res } = await uploadManageFile({ file, idItem });
        uploadedFiles.push({
          file_name: file.name,
          file_url: res.data.url,
          file_size: Number(res.data.fileSize),
          date_upload: res.data.date_upload,
          isLoading: true,
        });
      } catch (error) {
        console.error(error);
      }
    }

    const finalFileList = processFiles(fileList, uploadedFiles);
    setFileList(finalFileList);

    e.target.value = null;
  };

  const formatFileSize = size => {
    const units = ['Byte', 'KB', 'MB', 'GB', 'TB'];
    let unitIndex = 0;
    let formattedSize = size;

    while (formattedSize >= 1024 && unitIndex < units.length - 1) {
      formattedSize /= 1024;
      unitIndex++;
    }

    return {
      filesize: size <= 1024 ? size : formattedSize?.toFixed(3),
      unit: size <= 1024 ? `Byte` : units[unitIndex],
    };
  };

  const formatFileTotal = filesArray => {
    let totalSize = 0;
    const filesData = filesArray.map(file => {
      totalSize += file?.file_size || file?.size;
    });

    const setting = formatFileSize(totalSize);

    return `${setting.filesize}${setting.unit}`;
  };

  const imageChanged = async (e, prefix) => {
    try {
      setProcessing(true);
      const files = e.target.files;
      const file = files[0];

      const maxSizeInBytes = 3 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        setProcessing(false);
        e.target.value = '';
        return alert('Upload file size is limited to 3MB.');
      } else {
        const { data: res } = await uploadManageImg({
          file: files[0],
          idItem,
          prefix,
        });
        if (res.returnCode === 'C00008') {
          setProcessing(false);
          alert(res?.message);
        } else {
          if (index === 0) {
            setCoverImg({
              listImg4c: res.data.url476,
              listImg3c: res.data.url666,
              listImg: res.data.url,
            });
          } else {
            setCoverImgHover({
              listImg4g: res.data.url476,
              listImg3g: res.data.url666,
              listImg2: res.data.url,
            });
          }

          setProcessing(false);
          e.target.value = '';
        }
      }
    } catch (e) {
      e.target.value = '';
      alert('request failed');
    } finally {
      setProcessing(false);
    }
  };

  const onClickImgZoom = key => {
    setBigSizeImg(true);

    const coverImgUrl = coverImg?.listImg || coverImg;
    const hoverImgUrl = coverImgHover?.listImg2 || coverImgHover;
    setZoomImg(key === 1 ? hoverImgUrl : coverImgUrl);
  };

  const onClickCloseImg = () => {
    setBigSizeImg(false);
    setZoomImg('');
  };

  const onClickDeleteImg = key => {
    if (key === 0) {
      setCoverImg('');
    } else {
      setCoverImgHover('');
    }
  };

  const handleMouseOver = key => {
    setIsHovering(key);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const onClickDeleteFile = (key, file) => {
    const filterList = fileList.filter((el, index) => index !== key);
    setFileList(filterList);
    setTotalFileSize(formatFileTotal(filterList));

    setDeleteFiles(deleteFiles?.length === 0 ? [file] : [...deleteFiles, file]);
  };

  const handleClick = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const onChangePriceOption = item => {
    setPriceOption(item);

    if (item.value === 'free') {
      setRequiredData(prevData => ({
        ...prevData,
        priceUSD: '',
        discountRate: '',
      }));
    }
  };

  useEffect(() => {
    if (!modalIsOpen && failed) {
      document
        .querySelector('.errorContent')
        .scrollIntoView({ behavior: 'smooth', block: 'center' });
      setFailed(false);
    }
  }, [modalIsOpen, failed]);

  return (
    <ManageEditSection ref={requiredScroll}>
      {bigsizeImg && <PreviewImgView onClickCloseImg={onClickCloseImg} zoomImg={zoomImg} />}
      <p className='title'>Required Information </p>
      <p>
        If you omit even one piece of required information, you will not be able to publish the
        model.
      </p>
      <div className='input-wrap'>
        <div>
          <ManageUploadInput
            type='text'
            label='Model Name'
            id='modelName'
            value={modelName}
            name='modelName'
            onChange={onChange}
            placeholder='Please input text'
            maxLength={180}
            isRequired={requiredInfo?.requiredName}
            requireText='This field cannot be blank.'
          />
        </div>
        <div className='section-wrap'>
          <div className='container-wrap'>
            <p className='container-title'>
              Files{' '}
              {requiredInfo?.requiredFiles && (
                <span className='errorContent'>* Please upload at least one file.</span>
              )}
              {requiredInfo?.requiredFilesLoading && <span className='errorContent'></span>}
            </p>
            {/* 파일 업로드 */}
            <div className='btn-wrap'>
              <div className='btn-left'>
                <div className='btn-width'>
                  <form>
                    <input
                      ref={fileRef}
                      className='file-input'
                      type='file'
                      multiple
                      accept='.stl,.zip,.txt,.pdf,.3mf'
                      hidden
                      onChange={handleFileChange}
                    />
                    <ManageButton
                      text='+ Add Files'
                      backgroundColor='#4CBE37'
                      color='#fff'
                      onClick={handleClick}
                      type='button'
                      border={requiredInfo?.requiredFiles ? '1px solid #FF0000' : ''}
                    />
                  </form>
                </div>
                <span className='color-gray'>Accepted formats - stl, 3mf, txt, pdf, zip</span>
              </div>

              <span className='total-size'>
                Total file size: {totalFileSize ? totalFileSize : formatFileTotal(fileList)}
              </span>
            </div>
            {fileList.length > 0 && (
              <div className='file-wrap'>
                {fileList?.map((file, key) => {
                  return (
                    <FileBox
                      key={key}
                      onMouseOver={() => handleMouseOver(key)}
                      onMouseOut={handleMouseOut}
                      border={
                        requiredInfo?.requiredFilesLoading && !file?.file_url
                          ? '1px solid #FF0000'
                          : ''
                      }
                    >
                      <div className='modelupload'>
                        <div className='file-name'>
                          <span>{file.file_name ? file.file_name : 'Uploading...'}</span>
                        </div>
                        <div className='file-size'>
                          {isHovering === key && file?.file_size ? (
                            <button
                              className='btn-trash'
                              onClick={() => onClickDeleteFile(key, file)}
                            >
                              <img
                                src='/assets/img/mange/icon_trash_fff.png'
                                className='hover-icon'
                              />
                            </button>
                          ) : !file?.file_size ? ( // 파일 사이즈가 없을 때
                            <img src='/assets/img/mange/icon_loading.png' className='hover-icon' />
                          ) : (
                            <>
                              <span>{formatFileSize(file.file_size)?.filesize}</span>
                              <span>{formatFileSize(file.file_size)?.unit}</span>
                            </>
                          )}
                        </div>
                      </div>
                    </FileBox>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className='ctg-wrap section-wrap'>
          <div className='container-wrap'>
            <p className='container-title'>Price Option </p>
          </div>
          <div className='drop-box'>
            <ManageDropBox
              data={priceOptionList}
              setDropSelectValue={onChangePriceOption}
              dropSelectValue={priceOption}
              placeholderSize='16px'
            />
          </div>
        </div>

        <div className='price-wrap section-wrap'>
          {inputs?.map((input, index) => (
            <ManageUploadInput
              key={index}
              type={input.type}
              label={input.label}
              placeholder={input.placeholder}
              id={input.id}
              value={input.value}
              name={input.name}
              onChange={onChange}
              isRequired={requiredInfo?.requiredPrice}
              symbol={input.symbol}
              textAlign='right'
              disabled={input.disabled}
              backgroundColor={input.backgroundColor}
              onKeyDown={e => positiveValueWithDecimal(e)}
              symbolSize={16}
            />
          ))}
        </div>

        <span className='price-info'>
          If the final price is less than $3, the fixed fee of $0.3 for the payment gateway is
          deducted from the final settlement amount.
        </span>
        {requiredInfo?.requiredPrice && (
          <span className='errorContent errorFlex'>
            * If the Price Option is Paid, the final price must be at least $1.{' '}
          </span>
        )}

        {/* coverimg */}
        <div className='cover-img '>
          <div className='container-wrap'>
            <p className='container-title'>
              Cover Image{' '}
              {requiredInfo?.requiredCoverImg && (
                <span className='errorContent'>* A cover image is required.</span>
              )}
            </p>
            <span className='gray'>
              Recommended Ratio - 1 : 1 / Accepted formats - jpg, jpeg, webp, png, gif (gif format
              is only allowed for the Cover Hover image)
            </span>
          </div>
          <div className='img-wrap'>
            <ImageWrapper
              imgSrc={coverImg?.listImg}
              index={0}
              label='Cover'
              isRequired={requiredInfo?.requiredCoverImg}
              acceptTypes='.jpg, .jpeg, .webp, .png'
              onClickImgZoom={onClickImgZoom}
              onClickDeleteImg={onClickDeleteImg}
              imageChanged={e => imageChanged(e, 'coverImage')}
              setIndex={setIndex}
            />
            <ImageWrapper
              imgSrc={coverImgHover?.listImg2}
              index={1}
              label='Cover Hover'
              isRequired={false}
              acceptTypes='.jpg, .jpeg, .webp, .png, .gif'
              onClickImgZoom={onClickImgZoom}
              onClickDeleteImg={onClickDeleteImg}
              imageChanged={e => imageChanged(e, 'hoverImage')}
              setIndex={setIndex}
            />
          </div>
        </div>
        {/* detailimg */}
        <div className='detail-img'>
          <div className='container-wrap'>
            <p className='container-title'>
              Detail Image{' '}
              {requiredInfo?.requiredDetailImg && (
                <span className='errorContent'>* Please upload at least one detail image.</span>
              )}
            </p>
            <span className='gray'>
              Recommended Ratio - 4 : 3 / Accepted formats - jpg, jpeg, webp, png, gif
            </span>
          </div>

          <ImgDnd
            detailImg={detailImg}
            setDetailImg={setDetailImg}
            onClickAddImg={onClickAddImg}
            idItem={idItem}
            requiredDetailImg={requiredInfo?.requiredDetailImg}
          />
        </div>
        <div className='ctg-wrap'>
          <div className='container-wrap'>
            <p className='container-title'>
              Category{' '}
              {requiredInfo?.requiredCtg && (
                <span className='errorContent'>* This field cannot be blank.</span>
              )}{' '}
            </p>
          </div>
          <div className='drop-box'>
            <ManageDropBox
              data={ctgList}
              setDropSelectValue={setDropSelectValue}
              dropSelectValue={dropSelectValue}
              placeholder='Please select one'
              placeholderSize={dropSelectValue === '' ? '12px' : '16px'}
              isRequired={requiredInfo.requiredCtg}
            />
          </div>
        </div>
      </div>
    </ManageEditSection>
  );
};

export default RequiredInfo;
