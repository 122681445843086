import React, { useState } from 'react';

import ManageModelList from './ManageModelList';
import UploadModels from './UploadModels';
import { editModelAtom } from '../../../../atom/atom';
import { useRecoilState } from 'recoil';

const ManageModels = ({ designerURL, moveScroll }) => {
  const [content, setContent] = useState('list');
  const [editModel] = useRecoilState(editModelAtom);

  return (
    <div>
      {content !== 'list' || editModel?.status ? (
        <UploadModels setContent={setContent} moveScroll={moveScroll} designerURL={designerURL} />
      ) : (
        <ManageModelList setContent={setContent} designerURL={designerURL} moveScroll={moveScroll} />
      )}
    </div>
  );
};

export default ManageModels;
