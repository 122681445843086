import React, { useEffect, useState } from 'react';
import ManageDashBoard from '../manageModelPage/ManageDashBoard';
import useWindowDimensions from '../../../../util/useWindowDimensions';

const ManageModelList = ({ setContent, designerURL, moveScroll }) => {
  const [isMobile, setIsMobile] = useState(false);
  const updateClick = () => {
    setContent('upload');
    moveScroll();
  };
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width < 700) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  return (
    <div>
      <ManageDashBoard
        updateClick={updateClick}
        designerURL={designerURL}
        moveScroll={moveScroll}
        isMobile={isMobile}
      />
    </div>
  );
};

export default ManageModelList;
