import ManageButton from '../../../mangemodel/common/ManageButton';
import ManageDropBox from '../../../mangemodel/common/ManageDropBox';
import ManageSearchInput from '../../../mangemodel/common/ManageSearchInput';
import DesignerItemList from '../../../mangemodel/dashBoard/DesignerItemList';
import ManageCheckBox from '../../../mangemodel/common/ManageCheckBox';
import {
  Box,
  ButtonBox,
  CheckBoxWrapper,
  DashBoardForm,
  InputBox,
  ManageDashBoardStyle,
  TotalModels,
  UploadButtonBox,
  Wrapper,
} from './manageDashBoardStyle';
import { useEffect, useRef, useState, useCallback } from 'react';
import NewSortBox from '../../../mangemodel/common/NewSortBox';
import {
  getDesignerManageCategory,
  getDesignerManageModel,
  postManageModelDraft,
} from '../../../../action/request';
import Pagenation from '../../Pagenation';
import { useRecoilState } from 'recoil';
import { managePrevAtom, manageEditIdAtom } from '../../../../atom/atom';

const ManageDashBoard = ({ updateClick, designerURL, moveScroll, isMobile }) => {
  const ctgAllValue = { name: 'All', id_category: '' };
  const [page, setPage] = useState(1);
  const [selectedOption, setSelectedOption] = useState({ name: 'Latest', sortType: 'latest' });
  const [dropSelectValue, setDropSelectValue] = useState(ctgAllValue);
  const [, setEditManageId] = useRecoilState(manageEditIdAtom);
  const [searchValue, setSearchValue] = useState('');
  const [manageData, setManageData] = useState({});
  const [catagoryList, setCatagoryList] = useState([]);
  const [deletedModel, setDeletedModel] = useState();
  const [noFound, setNofound] = useState();
  const [noUploaded, setNoUploaded] = useState();
  const [prevState, setPrevState] = useRecoilState(managePrevAtom);

  const setPageNation = () => {};

  const [params, setParams] = useState(
    prevState.param
      ? prevState.param
      : {
          idCategory: '',
          modelName: '',
          status: '',
          sortType: '',
          offset: 0,
        }
  );

  const { idCategory, modelName, status } = params;

  const [checkBoxOptions, setCheckBoxOptions] = useState([
    { id: 1, name: 'Public', value: true, status: 'public' },
    { id: 2, name: 'Draft', value: true, status: 'draft' },
    { id: 3, name: 'Not for sale', value: true, status: 'secret' },
  ]);

  const sortOptions = [
    { name: 'Latest', sortType: 'latest' },
    { name: 'Most downloaded', sortType: 'download' },
    { name: 'High Price', sortType: 'highPrice' },
    { name: 'Low Price', sortType: 'lowPrice' },
  ];

  function submitData(item) {
    setParams({
      idCategory: item ? item.id_category : dropSelectValue ? dropSelectValue?.id_category : '',
      modelName: searchValue ? searchValue?.replace(/\s+/g, '') : '',
      status: checkBoxOptions
        .filter(option => option?.value === true)
        .map(option => option?.status)
        .join(','),
      sortType: selectedOption?.sortType,
      offset: 0,
    });
    setPage(1);
    setNofound(true);
  }

  const handleSubmit = e => {
    e.preventDefault();
    const nocheckBoxOptions = checkBoxOptions?.filter(el => el.value === false);
    if (nocheckBoxOptions?.length === 3) {
      setPage(1);
      setNofound(true);
      setManageData({});
    } else {
      submitData();
    }
  };

  const handleReset = () => {
    setCheckBoxOptions(prevOptions =>
      prevOptions.map(option => ({
        ...option,
        value: true,
      }))
    );
    setDropSelectValue(ctgAllValue);
    setSearchValue('');
    setPrevState(false);
    setParams({
      idCategory: '',
      modelName: '',
      status: '',
      sortType: '',
      offset: 0,
    });
  };

  const goToUpload = async () => {
    const { data: modelResponse } = await postManageModelDraft(designerURL);

    const result = modelResponse?.result;

    if (result?.id_item) {
      setEditManageId(result?.id_item);
      updateClick();
    }
  };

  const fetchDesignerCtg = async () => {
    const { data: ctgData } = await getDesignerManageCategory();
    const data = ctgData?.data;
    setCatagoryList([ctgAllValue, ...data]);

    if (prevState) {
      const paramsData = prevState.param;
      setPage(prevState?.page);
      const filtersortType = sortOptions?.filter(el => el.sortType === paramsData.sortType);

      setSelectedOption(filtersortType[0]);
      const selectedStatusesArray = paramsData.status.split(',');
      const updatedOptions = checkBoxOptions.map(option => ({
        ...option,
        value: selectedStatusesArray.includes(option.status),
      }));
      setCheckBoxOptions(paramsData?.status === '' ? checkBoxOptions : updatedOptions);
      setSearchValue(paramsData?.modelName);
      const filterctg = data.filter(el => Number(el.id_category) === Number(paramsData.idCategory));
      setDropSelectValue(filterctg.length === 0 ? ctgAllValue : filterctg[0]);
    }
  };

  const fetchModelsData = useCallback(async () => {
    const filter = checkBoxOptions?.filter(option => option.value !== true);
    const selectedApi = idCategory == '' && modelName == '' && status == '' && filter?.length === 0;

    const { data } = await getDesignerManageModel(designerURL, params);

    if (data.returnCode === 'C00000') {
      const resultData = data.data;
      setManageData(resultData);
      setNoUploaded(selectedApi && resultData.count === 0 ? true : false);
      setTimeout(() => {
        window.scrollTo({ top: prevState?.scroll });
        setPrevState(false);
      }, 300);
    }
  }, [params, deletedModel]);

  useEffect(() => {
    fetchDesignerCtg();
  }, []);

  useEffect(() => {
    fetchModelsData();
  }, [fetchModelsData]);

  const onchagePage = index => {
    const paramschange = {
      ...params,
      offset: (index - 1) * 15,
    };
    setParams(paramschange);
    setPage(index);
  };

  const onchageSort = type => {
    const paramschange = {
      ...params,
      sortType: type.sortType,
      offset: 0,
    };
    setSelectedOption(type);
    setPage(1);
    setParams(paramschange);
  };

  return (
    <ManageDashBoardStyle>
      <section>
        <DashBoardForm>
          <InputBox>
            {catagoryList && (
              <ManageDropBox
                titleLeft={true}
                title='Category'
                placeholder='Select'
                placeholderSize='16px'
                setDropSelectValue={setDropSelectValue}
                dropSelectValue={dropSelectValue}
                data={catagoryList}
                setParams={setParams}
                params={params}
                submitData={submitData}
              />
            )}

            <ManageSearchInput
              label='Model Name'
              placeholder='Please enter a keyword'
              onChange={e => setSearchValue(e.target.value)}
              value={searchValue}
            />
          </InputBox>
          <Box>
            <CheckBoxWrapper>
              <ManageCheckBox
                checkBoxOptions={checkBoxOptions}
                setCheckBoxOptions={setCheckBoxOptions}
              />
            </CheckBoxWrapper>
            <ButtonBox isMobile={isMobile}>
              <ManageButton
                text='Search'
                backgroundColor='#9A9A9A'
                color='#fff'
                onClick={handleSubmit}
              />
              <ManageButton
                text='Reset'
                backgroundColor='#fff'
                color='#9a9a9a'
                border='1px solid #9a9a9a'
                onClick={handleReset}
              />
            </ButtonBox>
          </Box>
        </DashBoardForm>
      </section>
      <section>
        <Wrapper isMobile={isMobile}>
          <TotalModels>{manageData?.count?.toLocaleString()} Models</TotalModels>
          <UploadButtonBox>
            <NewSortBox setSelectedOption={onchageSort} selectedOption={selectedOption} />
            {!isMobile && (
              <ManageButton
                text='+ Upload'
                backgroundColor='#4CBE37'
                color='#fff'
                type='button'
                onClick={goToUpload}
              />
            )}
          </UploadButtonBox>
        </Wrapper>
      </section>
      <DesignerItemList
        data={manageData?.list}
        setDeletedModel={setDeletedModel}
        updateClick={updateClick}
        noFound={noFound}
        noUploaded={noUploaded}
        isMobile={isMobile}
        page={page}
        params={params}
        goToUpload={goToUpload}
      />
      {manageData && (
        <Pagenation
          count={manageData?.count}
          viewCount={15}
          page={page}
          setPage={onchagePage}
          setPageNation={setPageNation}
          moveScroll={moveScroll}
        />
      )}
    </ManageDashBoardStyle>
  );
};

export default ManageDashBoard;
