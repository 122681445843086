import React, { createContext, useState } from 'react';

const UserContext = createContext({
  state: {
    isLogin: null,
    thumbnail: null,
    id: '',
    email: '',
    name: '',
    shortDesc: '',
    snsUserInfo: {},
    isDesigner: false,
    idStudio: '',
    studioName: '',
    isApp: false,
  },
  action: {
    setIsLogin: () => {},
    setThumbnail: () => {},
    setId: () => {},
    setEmail: () => {},
    setName: () => {},
    setShortDesc: () => {},
    setInit: () => {},
    setUserInfo: () => {},
    setIsDesigner: () => {},
    setIdStudio: () => {},
    setStudioName: () => {},
    setIsApp: () => {},
    setSnsUserInfo: () => {},
  },
});

const UserProvider = ({ children }) => {
  // TODO 로그인 기능 구현 시 변경
  // userIdMock 으로 구현되어있는 API 변경
  const [isLogin, setIsLogin] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [id, setId] = useState(null);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [shortDesc, setShortDesc] = useState('');
  const [snsUserInfo, setSnsUserInfo] = useState({});
  const [isDesigner, setIsDesigner] = useState(false);
  const [idStudio, setIdStudio] = useState('');
  const [studioName, setStudioName] = useState('');
  const [isApp, setIsApp] = useState(false);

  const setInit = () => {
    setIsLogin(false);
    setThumbnail(null);
    setId(null);
    setEmail('');
    setName('');
    setShortDesc('');
    setIsDesigner(false);
    setIdStudio('');
    setStudioName('');
    setIsApp(false);
  };

  const value = {
    state: {
      isLogin,
      thumbnail,
      id,
      email,
      name,
      shortDesc,
      snsUserInfo,
      isDesigner,
      idStudio,
      studioName,
      isApp,
    },
    action: {
      setIsLogin,
      setThumbnail,
      setId,
      setEmail,
      setName,
      setShortDesc,
      setInit,
      setSnsUserInfo,
      setIsDesigner,
      setIdStudio,
      setStudioName,
      setIsApp,
    },
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const { Consumer: UserConsumer } = UserContext;

export { UserProvider, UserConsumer };
export default UserContext;
