import moment from 'moment';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist({
  key: 'atom', // this key is using to store data in local storage
  storage: localStorage, // configurate which storage to use
});

export const isMobileAtom = atom({
  key: 'isMobileAtom', // unique ID (with respect to other atoms/selectors)
  default: '', // default value (aka initial value)
});

export const isLoginAtom = atom({
  key: 'isLoginAtom', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});

//회원가입이 성공하면 wellcomeModal을 띄우기 위한 atom
export const isWelcomeModal = atom({
  key: 'isWelcomeModal', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
  effects_UNSTABLE: [persistAtom],
});

// Layout.js 에 스크롤 값 저장 atom
export const scrollTo = atom({
  key: 'scrollTo', // unique ID (with respect to other atoms/selectors)
  default: 0, // default value (aka initial value)
});

// 브라우저 뒤로가기시 페이지네이션 유지를 위한 atom
export const paginationAtom = atom({
  key: 'paginationAtom',
  default: false,
});

export const designerPaginationAtom = atom({
  key: 'designerPaginationAtom',
  default: false,
});

//디자이너의 팔로우 버튼을 눌렀을 때 followers 탭에 있는 데이터를 새로 고침하기위한 atom
export const followStatusAtom = atom({
  key: 'followStatusAtom',
  default: false,
});

export const selectedTabAtom = atom({
  key: 'selectedTabAtom',
  default: 0,
});

export const selectedSortAtom = atom({
  key: 'selectedSortAtom',
  default: { title: 'Latest', value: 'Latest' },
});

export const selectedRefAtom = atom({
  key: 'selectedRefAtom',
  default: 0,
});

export const seletecdMoreAtom = atom({
  key: 'seletecdMoreAtom',
  default: 'my',
});

export const seletecdPidAtom = atom({
  key: 'seletecdPidAtom',
  default: '',
});

export const seletecdMonthAtom = atom({
  key: 'seletecdMonthAtom',
  default: '',
});
export const seletecdYearAtom = atom({
  key: 'seletecdYearAtom',
  default: moment().format('YYYY'),
});

export const loginAtom = atom({
  key: 'loginAtom',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const likeAtom = atom({
  key: 'likeAtom',
  default: false,
});

//designer페이지에서 디자이너 리스트를 저장하기 위한 atom
export const preDesignerListAtom = atom({
  key: 'preDesignerListAtom',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const localDesignerDataAtom = atom({
  key: 'localDesignerDataAtom',
  default: [],
});

export const ctgSortAtom = atom({
  key: 'ctgSortAtom',
  default: false,
});

export const commentTotalCountAtom = atom({
  key: 'commentTotalCountAtom',
  default: false,
});

export const commentPrevAtom = atom({
  key: 'commentPrevAtom',
  default: 'null',
});

export const followIdAtom = atom({
  key: 'followIdAtom',
  default: false,
});

//NEW DESIGNER
export const sortStateAtom = atom({
  key: 'sortStateAtom',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const randomSortStateAtom = atom({
  key: 'randomSortStateAtom',
  default: true,
  effects_UNSTABLE: [persistAtom],
});

export const designerListAtom = atom({
  key: 'designerListAtom',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const scrollRefAtom = atom({
  key: 'scrollRefAtom',
  default: false,
});

export const manageEditIdAtom = atom({
  key: 'manageEditIdAtom',
  default: false,
});

export const managePrevAtom = atom({
  key: 'managePrevAtom',
  default: false,
});

export const itemDetailNavAtom = atom({
  key: 'itemDetailNavAtom',
  default: false,
});

export const manageProcessingAtom = atom({
  key: 'manageProcessingAtom',
  default: false,
});

export const orderPageAtom = atom({
  key: 'orderPageAtom',
  default: 1,
});

export const downloadProcessingAtom = atom({
  key: 'downloadProcessingAtom',
  default: false,
});

export const editModelAtom = atom({
  key: 'editModelAtom',
  default: {
    id: null,
    status: false,
  },
});

export const scrollMoveAtom = atom({
  key: 'scrollMoveAtom',
  default: false,
});

export const idsAtom = atom({
  key: 'idsAtom',
  default: '',
  effects_UNSTABLE: [persistAtom],
});

export const isMotilink = atom({
  key: 'isMotilink',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const reviewPageAtom = atom({
  key: 'reviewPageAtom',
  default: 1,
});

export const commentPageAtom = atom({
  key: 'commentPageAtom',
  default: 1,
});
