// import styled from 'styled-components';
// import { ImgStyled } from '../../styles/ImgStyled';

// //designersPage
// export const DesignersContainer = styled.div`
//   width: 100%;
//   height: ${props => props.height};
//   overflow: hidden;

//   .designerNav {
//     margin: 0 auto;
//     max-width: 1340px;
//     position: relative;
//     padding: 0 1.5rem;
//     height: 51px;
//   }

//   @media screen and (max-width: 480px) {
//     .designerNav {
//       padding: 0px;
//     }

//     .shopItemCard .priceRow > .price {
//       font-size: 18px;
//     }
//   }
// `;

// // models , reivews
// export const CardWrap = styled.div`
//   display: grid;
//   grid-template-columns: repeat(4, 1fr);
//   grid-gap: 10px;
//   margin-top: 10px;

//   @media screen and (max-width: 780px) {
//     display: grid;
//     grid-template-columns: repeat(2, 1fr);
//   }
// `;

// export const Card = styled.div`
//   width: 100%;
// `;

// //designerHeader

// export const Header = styled.div`
//   padding-top: 40px;
//   padding: ${props => props.headerPadding && props.headerPadding};
//   background-image: ${props => props.designerSection};
//   background-size: cover;
//   border-radius: ${props => props.borderRadius && props.borderRadius + 'px'};
//   background-repeat: no-repeat;

//   .profileWrap .profile {
//     background-color: gray;
//   }

//   @media screen and (max-width: 1100px) {
//     .headerbg .flex {
//       width: 100%;
//       height: 100%;
//       display: flex;
//       flex-direction: column;
//       align-items: flex-start;
//     }

//     .headerbg .countBox {
//       display: flex;
//       flex-direction: row;
//     }

//     .headerbg .description {
//       margin-left: 0px;
//       width: 100%;
//     }

//     .headerbg .countBox {
//       margin-top: 30px;
//     }

//     .headerbg .countBox .box {
//       width: 100px;
//     }
//   }

//   @media screen and (max-width: 600px) {
//     background-image: ${props => props.designerPageMobile};
//     object-fit: contain;

//     .headerbg {
//       width: 100%;
//       padding: ${({ designerPagePadding }) => designerPagePadding};
//       box-sizing: border-box;
//     }
//     .profileWrap {
//       display: flex;
//       flex-direction: column;
//     }

//     .headerbg .flex {
//       display: none;
//     }

//     .desinerInfo {
//       margin-top: ${props => props.desinerInfo}px !important;
//     }

//     .headerbg .countBox {
//       width: 100%;
//       display: grid;
//       grid-template-columns: ${({ countBox }) => countBox};
//       margin-top: 20px;
//       margin-left: 0px;
//       justify-items: center;
//       gap: ${({ countBoGap }) => countBoGap}px;
//     }

//     .headerbg .countBox .box {
//       margin-right: 0px;
//     }

//     .headerbg .designerName {
//       font-size: 34px;
//     }

//     .viewDesignerList {
//       margin-top: 40px !important;
//       margin-bottom: 30px !important;
//       font-size: 14px !important;
//     }

//     .ellipsis {
//       -webkit-line-clamp: 3 !important;
//     }

//     .description {
//       margin-bottom: 0px !important;
//       margin-top: 30px !important;
//     }
//   }
// `;

// export const HeaderBg = styled.div`
//   /* padding: ${props => (props.padding ? props.padding : '16px 30px 50px 30px')}; */
//   width: 100%;
//   height: 100%;
//   max-width: 1360px;
//   margin: 0px auto;
//   position: relative;
//   display: flex;
//   flex-direction: column;

//   .viewDesignerList {
//     color: #fff;
//     font-weight: 600;
//     margin-top: 20px;
//     margin-bottom: 18px;
//     font-size: 16px;
//     font-family: 'Inter';

//     &:before {
//       display: inline-block;
//       content: '';
//       background-image: url('/assets/img/designers/arrow_back_ios.png');
//       background-size: contain;
//       background-repeat: no-repeat;
//       width: 7px;
//       height: 10px;
//       margin-right: 6px;
//     }
//   }

//   .flex {
//     width: 100%;
//     height: 130px;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//   }

//   .designer {
//     display: flex;
//   }

//   .profileWrap {
//     display: flex;
//     height: 100px;
//   }

//   .desinerInfo {
//     display: flex;
//     flex-direction: column;
//     justify-content: space-around;
//   }
//   .btnWrap {
//     display: flex;
//   }

//   .designerName {
//     color: #fff;
//     font-size: 36px;
//     font-weight: 600;
//     margin-bottom: 5px;

//     display: flex;
//     flex-direction: row;
//     align-items: center;
//   }
//   .countBox {
//     display: grid;
//     grid-template-columns: repeat(5, 1fr);
//     gap: 6px;
//   }

//   .description {
//     width: 800px;
//     display: flex;
//     flex-direction: column;
//     margin-top: 38px;
//     margin-left: 144px;
//     margin-bottom: 50px;

//     span {
//       color: #fff;
//       line-height: 25px;
//       font-size: 14px;
//       font-weight: 200;
//     }

//     .ellipsis {
//       text-overflow: ellipsis;
//       overflow: hidden;
//       word-break: break-word;

//       display: -webkit-box;
//       -webkit-line-clamp: 2;
//       -webkit-box-orient: vertical;
//     }

//     .readMore {
//       text-decoration: underline;
//       text-underline-offset: 3px;
//       color: #fff;
//       font-size: 15px;
//       font-weight: 500;

//       margin-top: 14px;
//     }
//   }
// `;

// export const Img = styled(ImgStyled)`
//   width: ${props => props.width}px;
//   height: ${props => props.height || props.width}px;
//   margin-right: ${props => props.right || 0}px;
//   margin-left: ${props => props.left || 0}px;
//   border-radius: ${props => props.radius || 0}%;
// `;

// export const Contents = styled.div`
//   min-height: 400px;
//   padding-bottom: 100px;
// `;

// export const MyContents = styled.div`
//   margin-top: 0px !important;
//   padding-top: 0px !important;

//   .fab-mypage-mysales-table {
//     margin-top: 0px !important;
//   }

//   @media screen and (max-width: 480px) {
//     .__scroll-area {
//       min-width: 480px;
//     }
//   }
// `;

// export const BackBtn = styled.div`
//   margin-bottom: ${props => props.bottom || 0}px;

//   .fab-mypage-mysales-table .__footer {
//     margin-top: 0px !important;
//   }
//   .__footer .fab-btn-back {
//     display: flex;
//     align-items: center;
//     border: 0;
//     padding: 0;
//     background: rgba(0, 0, 0, 0);
//     border-radius: 0;
//     font-family: Montserrat, sans-serif !important;
//     font-size: 1.125rem;
//     font-weight: 700;
//     line-height: 1.2;
//   }
//   .__footer .fab-btn-back svg {
//     display: block;
//     margin-right: 0.4444444444em;
//     width: 2em;
//   }
// `;

// export const ViewDetailsBtn = styled.button`
//   color: #fff !important;
//   width: 158px;
//   height: 42px;
//   border: 1px solid #ffffffb2 !important;
//   border-radius: 100px;
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   span {
//     font-size: 15px;
//     font-weight: 600;
//     font-family: 'Outfit';
//   }

//   img {
//     width: 18px;
//     height: 13px;
//     margin: 0 6px;
//   }
// `;

// export const DropDownContainer = styled.div`
//   margin: 0px auto;
//   max-width: 1340px;
//   position: relative;
//   padding: 0px 1.5rem;
//   margin-top: 30px;
//   display: flex;
//   justify-content: flex-end;
// `;

import styled from 'styled-components';
import { ImgStyled } from '../../styles/ImgStyled';

//designersPage
export const DesignersContainer = styled.div`
  width: 100%;
  height: ${props => props.height};
  overflow: hidden;

  .designerNav {
    margin: 0 auto;
    max-width: 1340px;
    position: relative;
    height: 51px;
  }

  @media screen and (max-width: 480px) {
    .designerNav {
      padding: 0px;
    }

    .shopItemCard .priceRow > .price {
      font-size: 18px;
    }
  }
`;

export const AppDesignersContainer = styled.div`
  width: 100%;
  height: ${props => props.height};
  // overflow: hidden;

  .designerNav {
    margin: 0 auto;
    max-width: 1340px;
    position: relative;
    height: 51px;
  }

  @media screen and (max-width: 480px) {
    .designerNav {
      padding: 0px;
    }

    .shopItemCard .priceRow > .price {
      font-size: 18px;
    }
  }
`;

// models , reivews
export const CardWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;

  @media screen and (max-width: 780px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;
  }
`;

export const Card = styled.div`
  width: 100%;
`;

//designerHeader

export const Header = styled.div`
  .onlydesignersHeader {
    padding-top: 43px;
    background-image: url(/assets/img/designers/designerBg.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 43px 30px 0px 30px;

    .countBox {
      margin-left: 228px;
    }
  }

  .designersHeader {
    padding: 15px 30px;
    background-image: url(/assets/img/designers/designersBg.png);
    background-size: cover;
    border-radius: 10px;
    background-repeat: no-repeat;

    .countBox .title {
      margin-bottom: 16px;
    }
  }

  @media screen and (max-width: 650px) {
    .onlydesignersHeader {
      padding: 70px 20px 50px 20px !important;
      background-image: url(/assets/img/designers/designerpage_bg_m.png);
      background-repeat: no-repeat;
      background-size: cover;

      .headerbg .countBox {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 0fr);
        margin-top: 30px;
        margin-left: 0px;
        justify-items: center;
        gap: 10px;
      }

      .btnWrap {
        margin-top: 18px;
      }

      .countBox {
        margin-left: 0px;
      }
    }

    .designersHeader {
      background-image: url(/assets/img/designers/designerlist_m.png);
      object-fit: contain;
      padding: 30px 28px;

      .headerbg .countBox {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 0fr);
        margin-top: 20px;
        margin-left: 0px;
        justify-items: center;
        gap: 7px;
      }

      .countBox .title {
        margin-bottom: 8px;
      }

      .btnWrap {
        padding-top: 16px;
      }

      .btnWrap #follow img {
        margin-right: 6px;
      }
    }
  }

  .profileWrap .profile {
    background-color: gray;
  }

  @media all and (min-width: 650px) and (max-width: 1300px) {
    .onlydesignersHeader {
      padding: 50px 20px 0px 20px;
      .countBox {
        margin-left: 140px;
      }
    }
  }

  @media screen and (max-width: 1100px) {
    .onlydesignersHeader {
      padding: 40px 20px 0px 20px;

      .countBox {
        margin-left: 150px;
      }
    }

    .headerbg .flex {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .headerbg .countBox {
      display: flex;
      flex-direction: row;
    }

    .headerbg .description {
      margin-left: 0px;
      width: 100%;
    }

    .headerbg .countBox {
      margin-top: 30px;
    }

    .headerbg .countBox .box {
      width: 100px;
    }

    .onlydesignersHeader .countBox {
      margin-left: 0px;
    }
  }

  @media screen and (max-width: 600px) {
    background-image: ${props => props.designerPageMobile};
    object-fit: contain;

    .headerbg {
      width: 100%;
      padding: ${({ designerPagePadding }) => designerPagePadding};
      box-sizing: border-box;
    }
    .profileWrap {
      display: flex;
      flex-direction: column;
    }

    .headerbg .flex {
      display: none;
    }

    .desinerInfo {
      margin-top: ${props => props.desinerInfo}px !important;
    }

    .headerbg .countBox {
      width: 100%;
      display: grid;
      grid-template-columns: ${({ countBox }) => countBox};
      margin-top: 20px;
      margin-left: 0px;
      justify-items: center;
      gap: ${({ countBoGap }) => countBoGap}px;
    }

    .headerbg .countBox .box {
      margin-right: 0px;
    }

    .headerbg .designerName {
      font-size: 34px;
    }

    .viewDesignerList {
      margin-bottom: 30px !important;
      font-size: 14px !important;
    }

    .ellipsis {
      -webkit-line-clamp: 3 !important;
    }

    .description {
      margin-bottom: 0px !important;
      margin-top: 30px !important;
    }
  }
`;

export const HeaderBg = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1340px;
  margin: 0px auto;
  position: relative;
  display: flex;
  flex-direction: column;
  .viewDesignerList {
    color: #fff;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 18px;
    font-size: 16px;
    font-family: 'Inter';

    &:before {
      display: inline-block;
      content: '';
      background-image: url('/assets/img/designers/arrow_back_ios.png');
      background-size: contain;
      background-repeat: no-repeat;
      width: 7px;
      height: 10px;
      margin-right: 6px;
    }
  }

  .flex {
    width: 100%;
    height: 130px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .designer {
    display: flex;
  }

  .profileWrap {
    display: flex;
    height: 100px;
  }

  .desinerInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .btnWrap {
    display: flex;
  }

  .designerName {
    color: #fff;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 5px;

    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .countBox {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 6px;
  }

  .description {
    width: 900px;
    display: flex;
    flex-direction: column;
    margin-top: 38px;
    margin-left: 144px;
    margin-bottom: 50px;

    span {
      color: #fff;
      line-height: 25px;
      font-size: 14px;
      font-weight: 200;
      font-family: 'inter';
    }

    .ellipsis {
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-word;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .readMore {
      text-decoration: underline;
      text-underline-offset: 3px;
      color: #fff;
      font-size: 15px;
      font-weight: 400;

      margin-top: 14px;
      font-family: 'inter';
    }
  }
`;

export const Img = styled(ImgStyled)`
  width: ${props => props.width}px;
  height: ${props => props.height || props.width}px;
  margin-right: ${props => props.right || 0}px;
  margin-left: ${props => props.left || 0}px;
  border-radius: ${props => props.radius || 0}%;
`;

export const Contents = styled.div`
  min-height: 400px;
  padding-bottom: 100px;
  padding: 0px !important;

  @media screen and (max-width: 1340px) {
    margin: 0px 20px !important;
  }
`;

export const MyContents = styled.div`
  margin-top: 0px !important;
  padding-top: 0px !important;

  .fab-mypage-mysales-table {
    margin-top: 0px !important;
  }

  @media screen and (max-width: 480px) {
    .__scroll-area {
      min-width: 480px;
    }
  }
`;

export const BackBtn = styled.div`
  margin-bottom: ${props => props.bottom || 0}px;

  .fab-mypage-mysales-table .__footer {
    margin-top: 0px !important;
  }
  .__footer .fab-btn-back {
    display: flex;
    align-items: center;
    border: 0;
    padding: 0;
    background: rgba(0, 0, 0, 0);
    border-radius: 0;
    font-family: Montserrat, sans-serif !important;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.2;
  }
  .__footer .fab-btn-back svg {
    display: block;
    margin-right: 0.4444444444em;
    width: 2em;
  }
`;

export const ViewDetailsBtn = styled.button`
  color: #fff !important;
  width: 158px;
  height: 42px;
  border: 1px solid #ffffffb2 !important;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: 15px;
    font-weight: 600;
    font-family: 'Outfit';
  }

  img {
    width: 18px;
    height: 13px;
    margin: 0 6px;
  }
`;

export const DropDownContainer = styled.div`
  margin: 0px auto;
  max-width: 1340px;
  position: relative;

  margin-top: 30px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 1340px) {
    margin-right: 20px;
  }
  @media screen and (max-width: 650px) {
    margin-bottom: 30px;
    margin-top: 40px;
  }
`;
