import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { baseUrl } from '../../../action/request';
import { SocialButton } from '../../layout/style/SignupStyle';

function Twitter() {
  const location = useLocation();
  const currentPath = location.pathname;
  const currentSearch = location.search;

  const onClickTwitter = async e => {
    e.preventDefault();
    e.stopPropagation();
    localStorage.setItem(
      'loginPath',
      !currentSearch ? currentPath : `${currentPath}${currentSearch}`
    );
    window.location.href = `${baseUrl}sns/twitter/callback`;
    // console.log(window.location.href);
    // 로그인할 때 현재 위치 로컬스토리지에 저장
  };

  return (
    <SocialButton
      id='twitter-signup'
      className='btn btn-full social-button'
      onClick={onClickTwitter}
    >
      <div>
        <img src='/assets/img/login/x.png' alt='Twitter' />
        <p>X (Twitter)</p>
      </div>
    </SocialButton>
  );
}

export default Twitter;
