import React, { useRef, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Followrs from './Followrs';
import Models from './Models';
import Reviews from './Reviews';
import Comments from './Comments';
import MySales from './MySales';
import Dropdown from '../Dropdown';
import UserContext from '../../../context/user/user';
import { useRecoilState } from 'recoil';
import {
  designerPaginationAtom,
  selectedTabAtom,
  selectedSortAtom,
  seletecdMoreAtom,
  seletecdYearAtom,
  commentPrevAtom,
  managePrevAtom,
  editModelAtom,
  commentTotalCountAtom,
  followStatusAtom,
} from '../../../atom/atom';
import { Contents, DropDownContainer } from '../designers';
import moment from 'moment';
import ManageModels from './manageModel/ManageModels';
import { getDesignerInfoName, getDesignerInfoId } from '../../../action/request';
import { Webpage2StylesContainer } from '../../../styles/Webpage2Styles';
import { AppDesignersContainer } from '../../../component/designers/designers';
import { getUserInfo } from '../../../action/request';
import { ls } from '../../../constant';

const AppDesignersContents = ({ designerId, tabId }) => {
  // const scrollRef = useRef(null);

  const initalValue = { title: 'Latest', value: 'Latest' };
  const [selectedTab, setSelectedTab] = useState(tabId);
  const [selectedSort, setSeletedSort] = useRecoilState(selectedSortAtom);
  const [, setPageNation] = useRecoilState(designerPaginationAtom);
  const { state: userState, action: userAction } = useContext(UserContext);
  const isLogin = userState?.isLogin;
  const isDesigner = userState?.isDesigner;
  const [, setSelectedMore] = useRecoilState(seletecdMoreAtom);
  const [, setPrevComment] = useRecoilState(commentPrevAtom);
  const [, setPrevState] = useRecoilState(managePrevAtom);
  const [editModel, setEditModel] = useRecoilState(editModelAtom);
  const [, setSelectYear] = useRecoilState(seletecdYearAtom);
  // const scrollD = localStorage.getItem('scrollDesigner');
  const [designerInfo, setDesignerInfo] = useState();
  const [followValue, setFollowValue] = useState();
  const [totalCount, setTotalCount] = useRecoilState(commentTotalCountAtom);
  const [isFollow, setIsFollow] = useRecoilState(followStatusAtom);
  const [designerURL, setDesignerURL] = useState();
  // const { designerURL } = useParams();

  const ModelsSortOption = [
    { title: 'Latest', value: 'Latest' },
    { title: 'Most Downloaded', value: 'Downloads' },
    { title: 'Most Commented', value: 'Comment' },
    { title: 'Most Liked ', value: 'Like' },
  ];

  const FollowerSortOption = [
    { title: 'Latest', value: 'Latest' },
    { title: 'Top Downloaders', value: 'Download' },
  ];

  const ReviewSortOption = [
    { title: 'Latest', value: 'Latest' },
    { title: 'Most Commented', value: 'Comment' },
    { title: 'Most Liked', value: 'Like' },
  ];

  const sortNone = selectedTab === 2 || selectedTab === 4 || selectedTab === 5;

  useEffect(() => {
    checkIsLogin();
    setTotalCount(false);
  }, [designerURL, isFollow, isLogin]);

  useEffect(() => {
    if (editModel?.status) {
      setSelectedTab(4);
      return;
    }
    if (selectedTab === 5 || (selectedTab === 4 && userState.idStudio !== designerURL)) {
      setSelectedTab(0);
    }
  }, [designerURL, userState]);

  const moveScroll = () => {
    const moveTo = isMobile ? 120 : 110;
    // setTimeout(() => window.scrollTo({ top: scrollRef.current.offsetTop - moveTo }), 100);
  };

  // const onClickTab = nav => {
  //   setSelectedTab(nav.value);
  //   setPageNation(1);
  //   setPrevComment('null');
  //   setSeletedSort(initalValue);
  //   setPrevState(false);

  //   if (selectedTab !== 5) {
  //     setSelectedMore('my');
  //   }

  //   setSelectYear(moment().format('YYYY'));
  //   // if (nav.value !== 3) {
  //   //   moveScroll();
  //   // }
  // };

  function sortData(tab) {
    if (tab === 0) {
      return ModelsSortOption;
    } else if (tab === 1) {
      return FollowerSortOption;
    } else if (tab === 3) {
      return ReviewSortOption;
    }
  }

  const onChangeSort = sort => {
    setSeletedSort(sort);
    setPageNation(1);
  };

  // useEffect(() => {
  //   if (editModel?.status) {
  //     setTimeout(() => {
  //       window.scrollTo(0, 360);
  //     }, 500);
  //   } else if (scrollD > 0) {
  //     setTimeout(() => {
  //       window.scrollTo({ top: scrollD });
  //     }, 800);
  //   }
  // }, [scrollD]);

  const checkIsLogin = async () => {
    const isLoginUsingToken =
      localStorage.getItem(ls.accessToken) !== 'undefined' &&
      localStorage.getItem(ls.accessToken) !== 'null' &&
      localStorage.getItem(ls.accessToken);
    if (isLoginUsingToken) {
      try {
        const {
          data: { data: userInfo },
        } = await getUserInfo();

        userAction.setIsLogin(true);
        userAction.setThumbnail(userInfo.thumbnail);
        userAction.setId(userInfo.id_user);
        userAction.setEmail(userInfo.email);
        userAction.setName(userInfo.name);
        userAction.setIsDesigner(userInfo.designer);
        userAction.setIdStudio(userInfo.id_studio);
        userAction.setStudioName(userInfo.studio_name);
        setDesignerURL(userInfo.id_studio);
        // setIsLoginAtom(true);
      } catch (e) {
        // 유저 정보 에러 날 때 > 로컬스토리지 초기화, user context 초기화
        localStorage.removeItem(ls.accessToken);
        localStorage.removeItem(ls.refreshToken);
        userAction.setInit();
      }
    } else {
      // 유저 정보 에러 날 때 > 로컬스토리지 초기화, user context 초기화
      localStorage.removeItem(ls.accessToken);
      localStorage.removeItem(ls.refreshToken);
      userAction.setInit();
    }
  };

  return (
    <Webpage2StylesContainer>
      <AppDesignersContainer height={designerInfo ? '100%' : '100vh'}>
        {(sortNone || designerURL === undefined) ? (
          ''
        ) : (
          <DropDownContainer>
            <Dropdown
              options={sortData(selectedTab)}
              onChangeSort={onChangeSort}
              selectedSort={selectedSort}
            />
          </DropDownContainer>
        )}

        <Contents className='container'>
          {(tabId === 0 && designerURL) ? (
            <Models
              moveScroll={moveScroll}
              selectedSort={selectedSort}
              designerURL={designerURL}
              selectedTab={selectedTab}
            />
          ) : (tabId === 1 && designerURL) ? (
            <Followrs 
              selectedSort={selectedSort} 
              moveScroll={moveScroll} 
              data={designerURL} 
            />
          ) : (tabId === 2 && designerURL) ? (
            <Comments
              userState={userState}
              designerURL={designerURL}
              designerId={designerId}
              // scrollRef={scrollRef}
              isMobile={isMobile}
              moveScroll={moveScroll}
            />
          ) : (tabId === 3 && designerURL) ? (
            <Reviews
              moveScroll={moveScroll}
              value='review'
              selectedSort={selectedSort}
              designerURL={designerURL}
            />
          ) : (tabId === 4 && designerURL) ? (
            <ManageModels 
              designerURL={designerURL} 
              moveScroll={moveScroll} 
            />
          ) : (tabId === 5 && designerURL) ? (
            <MySales 
              moveScroll={moveScroll} 
            />
          ) : <></>}
        </Contents>
      </AppDesignersContainer>
    </Webpage2StylesContainer>
  );
};

export default AppDesignersContents;
