/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from 'react';
import { HOSTNAME, getPrinters, getSlicers, signup, validInvite } from '../../action/request';
import ModalContext from '../../context/modal/modal';
import { FOOTER_PRIVACY, FOOTER_TERMS } from '../../route/constant';
import './Signup.css';
import Apple from '../sns/Apple';
import Google from '../sns/Google';
import Facebook from '../sns/Facebook';
import Yahoo from '../sns/Yahoo';
import Twitter from '../sns/Twitter';
import Github from '../sns/Github';
import { loginSignupEn, loginSignupKo } from '../../context/lang/constant';
import LangContext from '../../context/lang/lang';
import { validateEmail } from '../../util/validate';
import { Ss } from '../../constant';
import { gtagOnClickSignup, gtagOnClickSignupAd } from '../../action/gTag';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  AgreementText,
  BoxContainer,
  ButtonWrapper,
  H2Style,
  HaveAccount,
  SignupContainer,
  SignUpHr,
  SignupStyle,
  SocialButton,
} from './style/SignupStyle';
import UserEmailSignUp from './UserEmailSignUp';

const Signup = ({ appValue }) => {
  const [printers, setPrinters] = useState([]);
  const [slicers, setSlicers] = useState([]);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [confirmPassword, setConfirmPassword] = useState('');
  const [selectedPrinter, setSelectedPrinter] = useState(0);
  const [selectedSlicer, setSelectedSlicer] = useState(0);
  const [inviteTokenState, setInviteTokenState] = useState();
  const [emailValue, setEmailValue] = useState(false);
  const [ladingPage, setLadingPage] = useState(false);
  const [isEmailSignUp, setIsEmailSignUp] = useState(false);
  const navigate = useNavigate();
  const { state, action } = useContext(ModalContext);
  const { state: langState } = useContext(LangContext);
  const isKorean = langState.lang.includes('ko');
  const loginSignupMessages = isKorean ? loginSignupKo : loginSignupEn;

  //googleAds ad.fab365 => fab365 유저의 신규가입 추적
  const loction = useLocation();

  useEffect(() => {
    if (loction?.search === '?t=google') {
      setLadingPage(true);
    }

    // fetchData();
  }, []);

  useEffect(() => {
    // 초대 토큰
    if (sessionStorage.getItem(Ss.inviteToken) === 'google') return;
    setInviteTokenState(sessionStorage.getItem(Ss.inviteToken));
  }, [sessionStorage.getItem(Ss.inviteToken)]);

  const fetchData = async () => {
    // TODO:GET 새로고침시 매번 데이터 통신하는 이유 문의
    try {
      const { data: printers } = await getPrinters();
      setPrinters(printers.data);
      const { data: slicers } = await getSlicers();
      setSlicers(slicers.data);
    } catch (e) {}
  };

  const onCreateAccount = async () => {
    // email, password 유효성 검사

    // if (!email.trim().length) return alert(loginSignupMessages.LOGIN_ERROR_EMAIL_UNSET);
    if (!emailValue) return alert(loginSignupMessages.SIGNUP_ERROR_EMAIL_INVALID);

    if (!validateEmail(email)) return alert(loginSignupMessages.LOGIN_ERROR_EMAIL_INVALID);
    if (!password.trim().length) return alert(loginSignupMessages.SIGNUP_ERROR_PASSWORD_NEED);
    if (password !== confirmPassword)
      return alert(loginSignupMessages.LOGIN_ERROR_PASSWORD_NOT_MATCH);
    try {
      // 초대 렌딩페이지
      if (inviteTokenState !== 'null' && inviteTokenState !== 'undefined' && inviteTokenState) {
        try {
          const { data } = await validInvite(inviteTokenState);
          if (data.result) {
            // 초대 토큰 유효하면 회원가입 진행
            onSignUp();
          } else {
            // 초대 토큰 유효하지 않음
            return alert('request failed');
          }
        } catch (e) {
          return alert(e);
        }
      } else {
        // 일반 회원가입
        onSignUp();
      }
    } catch (e) {}
  };

  const onSignUp = async () => {
    const params = {
      action: 'signup',
      params: {
        email: email.trim(),
        password: password.trim(),
        printer: selectedPrinter,
        slicer: selectedSlicer,
        invite_token: inviteTokenState,
        siteUrl: HOSTNAME,
        from: appValue ? 'M' : 'W',
      },
    };
    try {
      const { data } = await signup(JSON.stringify(params));

      if (data.data) {
        action.setIsSignup(false);
        alert(
          'We have sent an account verification email to the email address you entered. Please log in after completing authentication. If you can’t find the verification email, please check your spam folder.'
        );
        // gtag 일반 회원가입 추적 태그
        if (ladingPage) {
          gtagOnClickSignupAd();
          setLadingPage(false);
        } else {
          gtagOnClickSignup('Fab365');
        }

        return action.setIsLogin(true);
      }
      if (data.message) return alert(data.message);
    } catch (e) {}
  };

  function isEmail(email) {
    const emailRegex =
      /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return emailRegex.test(email);
  }

  const onChangeEmail = e => {
    const ko = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
    const regex = isEmail(e.target.value);
    if (!ko.test(e.target.value) && regex) {
      // console.log('통과', e.target.value);
      setEmailValue(true);
      setEmail(e.target.value);
    } else {
      setEmailValue(false);
      // console.log('통과아니야📌', e.target.value);
      setEmail(e.target.value);
    }
  };
  const onChangePassword = e => {
    setPassword(e.target.value);
  };
  const onChangeConfirmPassword = e => {
    setConfirmPassword(e.target.value);
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      onCreateAccount();
    }
  };

  const handleEmailSignUp = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsEmailSignUp(!isEmailSignUp);
  };

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(path, '_blank', 'noopener,noreferrer');
  };

  const closeModal = () => {
    document.body.classList.remove('modal-opened');
    action.setIsSignup(false);
    setIsEmailSignUp(false);
    if (appValue) {
      action.setIsLogin(true);
    }
  };

  const handleFocus = e => {
    if (e.target.name === 'email') {
      setEmail('');
    } else if (e.target.name === 'password') {
      setPassword('');
    } else if (e.target.name === 'confirmPassword') {
      setConfirmPassword('');
    }
  };

  return (
    <>
      {
        <div className={'modal' + (state.isSignup ? ' active' : '')} id='modalSignUp'>
          <div className='modal-inner'>
            <div className='modal-box modal-box-style'>
              <SignupContainer>
                <H2Style>Sign up</H2Style>
                <SignUpHr />
                <AgreementText>
                  By creating an account, you agree to Fab365's
                  <br />
                  <a
                    href=''
                    onClick={e => {
                      goTo(e, FOOTER_PRIVACY);
                    }}
                  >
                    Privacy Policy
                  </a>
                  &nbsp;and&nbsp;
                  <a
                    href=''
                    onClick={e => {
                      goTo(e, FOOTER_TERMS);
                    }}
                  >
                    Terms of Service
                  </a>
                </AgreementText>

                {isEmailSignUp ? (
                  <UserEmailSignUp
                    onCreateAccount={onCreateAccount}
                    email={email}
                    password={password}
                    onChangePassword={onChangePassword}
                    onChangeEmail={onChangeEmail}
                    handleKeyPress={handleKeyPress}
                    emailValue={emailValue}
                    confirmPassword={confirmPassword}
                    onChangeConfirmPassword={onChangeConfirmPassword}
                    setIsEmailSignUp={setIsEmailSignUp}
                    handleFocus={handleFocus}
                  />
                ) : (
                  <BoxContainer>
                    <form action='' name='signup_form' className='signup-form m-b-lg'>
                      <ButtonWrapper>
                        <Google />
                        <Facebook />
                        <Apple />
                        <Twitter />
                        <Yahoo />
                        <Github />
                        <SocialButton
                          className='btn btn-full social-button'
                          onClick={e => handleEmailSignUp(e)}
                        >
                          <div>
                            <img src='' alt='' style={{ visibility: 'hidden' }} />
                            <p>Use email</p>
                          </div>
                        </SocialButton>
                      </ButtonWrapper>
                    </form>
                  </BoxContainer>
                )}
                <a
                  onClick={() => {
                    action.setIsSignup(false);
                    closeModal();
                  }}
                  data-hide-modal='true'
                  className='btn-close'
                >
                  Close
                </a>
                <HaveAccount top={appValue ? 20 : 0}>
                  Have an account?
                  <a
                    onClick={() => {
                      action.setIsSignup(false);
                      action.setIsLogin(true);
                    }}
                    className='txt-green remodal-login'
                  >
                    Login
                  </a>
                </HaveAccount>
              </SignupContainer>
              {/* <UserEmailSignUp onCreateAccount={onCreateAccount} /> */}
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default Signup;
