import React, { useContext, useEffect } from 'react';
import Layout from '../../component/layout/Layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { createCartAdd, getFreeLimit, getSearchV2, toggleItemLike } from '../../action/request';
import { useState } from 'react';
import UserContext from '../../context/user/user';
import { useRecoilState } from 'recoil';
import { likeAtom, paginationAtom } from '../../atom/atom';
import styled from 'styled-components';
import { BannerWrapper, CollectionGrid } from '../Category/component/CollectionGridStyle';
import ModelCard from '../../component/common/itemCards/ModelCard';
import { Webpage2StylesContainer } from '../../styles/Webpage2Styles';
import useClickHandler from '../../component/common/itemCards/useCardClickHandeler';
import useDownload from '../../hook/useDownload';
import ModalContext from '../../context/modal/modal';
import CartContext from '../../context/cart.js/carts';
import { ls } from '../../constant';
import { gtagAddToCart } from '../../action/gTag';
import { updateLikeState } from '../../util/updateLikeState';
import Selectefile from '../../component/selectefile/Selectefile';
import Pagenation from '../../component/designers/Pagenation';
import GoogleAds from '../../component/adv/GoogleAds';
function Search() {
  const { oneClick, twoClick, handleClick } = useClickHandler();
  const {
    selectedId,
    selectedName,
    selectedModal,
    onClickDownload,
    setSelectedModal,
    setSelectedId,
  } = useDownload();
  const [likeState, setLikeState] = useRecoilState(likeAtom);
  const loadingCount = 24;
  const { state: userState } = useContext(UserContext);
  const { action: modalAction } = useContext(ModalContext);
  const { state: cartsState, action: cartsAction } = useContext(CartContext);
  const location = useLocation();
  const searchType = location?.search ? location.search.split('=')[0]?.slice(1) : '';
  const search = location?.search ? decodeURI(location.search.split('=')[1]?.split('&')[0]) : '';
  const [limit, setLimit] = useState({});
  const { state } = useContext(UserContext);
  const navigate = useNavigate();
  const [fetchedDataCount, setFetchedDataCount] = useState(null);

  const [searchItems, setSearchItems] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  // const [freeItemLimitDto, setFreeItemLimitDto] = useState({});
  const [index, setIndex] = useRecoilState(paginationAtom);

  const pageChange = value => {
    setPage(value);
    setIndex(value);
  };
  const pageConfirm = value => {
    setPage(value);
    setIndex(value);
  };

  const onGoItemDetail = (e, itemNo) => {
    // e.preventDefault();
    // e.stopPropagation();
    // navigate(`/items/${itemNo}`, { state: page });
    // localStorage.setItem('scroll', window.scrollY);
  };

  const goToStore = e => {
    e.preventDefault();
    e.stopPropagation();

    navigate('/categories');
  };

  const fetch = async () => {
    let fetchedData = null;
    try {
      const { data } = await getSearchV2(searchType, search, index === false ? page : index);
      fetchedData = data;
      if (data.data.keyword) {
        setSearchItems(data.data.keyword || data?.data?.count);
      } else {
        setSearchItems(data.data.tag || data?.data?.count);
      }
      // setFreeItemLimitDto(data.data.freeItemLimitDto);
      setPageCount(data.data.count);
      const { data: resFreeLimit } = await getFreeLimit();
      setLimit(resFreeLimit.data);
    } catch (e) {
      console.error('데이터를 가져오는 중 오류가 발생했습니다:', e);
    } finally {
      if (fetchedData) {
        setFetchedDataCount(fetchedData?.data?.count);
      }
    }
  };

  // 로그인 상태 변경때마다 fetchData() 호출
  useEffect(() => {
    if (index !== false) {
      setPage(index);
    }
    setSearchItems([]);
    fetch();
  }, [page, search, searchType, state?.isLogin]);

  const clickCart = async (e, price, id, inCart) => {
    // 이미 카트에 담겨있을 경우
    e.stopPropagation();
    if (inCart) return;

    if (!userState.isLogin) {
      // 로그인이 안되어 있을 때 로그인 모달을 보여준다.
      modalAction.setIsLogin(true);
    } else {
      try {
        const { data: res } = await createCartAdd(id);
        if (res) {
          // 기존 카트 갯수에 +1 을 한다.
          cartsAction.changeCartsCount(cartsState.cartsCount + 1);
          localStorage.setItem(ls.cartsCount, cartsState.cartsCount + 1);

          alert(res.result);

          // 기존 카드 상태에 카트 값 업데이트
          const newCards = searchItems.map(card =>
            card.product_id === id
              ? {
                  ...card,
                  in_cart: !card.in_cart,
                }
              : card
          );
          setSearchItems(newCards);

          // gtag 장바구니 추가 태그
          gtagAddToCart({ value: Number(price), itemId: id });
        }
      } catch (e) {}
    }
  };

  // const clickLike = async (e, id, count) => {
  //   e.stopPropagation();
  //   if (!userState?.isLogin) {
  //     modalAction.setIsLogin(true);
  //   } else {
  //     const { data } = await toggleItemLike({ idItem: id });
  //     if (data) {
  //       const updatedCards = await updateLikeState({
  //         id: id,
  //         setLikeState: setLikeState,
  //         cards: searchItems.results,
  //         count: count,
  //         data: data,
  //       });
  //       setSearchItems({ ...searchItems, results: updatedCards });
  //     }
  //   }
  // };

  const clickLike = async (e, id, count) => {
    e.stopPropagation();
    if (!userState?.isLogin) {
      modalAction.setIsLogin(true);
    } else {
      const { data } = await toggleItemLike({ idItem: id });
      if (data) {
        const updatedCards = await updateLikeState({
          id: id,
          setLikeState: setLikeState,
          cards: searchItems,
          count: count,
          data: data,
        });
        setSearchItems(updatedCards);
      }
    }
  };

  return (
    <Layout>
      <Webpage2StylesContainer>
        <IndexContainer id='main'>
          <div className='page-top page-top-lg'>
            <div className='container container-lg'>
              <h1 className='page-title02'>SEARCH RESULTS</h1>
              <h2 className='page-title-sub'>
                {searchType === 'tag' ? `#${search}` : `"${search}"`}
              </h2>
            </div>
          </div>
          <section className='store-collection'>
            <div className='container'>
              <div className='content-section'>
                <BannerWrapper margin={'0px 0px 60px 0px'}>
                  <GoogleAds type='typeA' height={92} />
                </BannerWrapper>
                <div className='product-list product-list-3'>
                  <CollectionGrid dataCount={fetchedDataCount}>
                    {searchItems.length > 0 ? (
                      searchItems.map((item, idx) => {
                        const isBanner = idx === 8 || idx === 16;
                        return (
                          <>
                            {isBanner && (
                              <BannerWrapper>
                                <GoogleAds type='typeA' height={92} />
                              </BannerWrapper>
                            )}
                            <ModelCard
                              key={idx}
                              handleClick={e => onGoItemDetail(e, item.product_id)}
                              twoClick={twoClick === idx}
                              oneClick={oneClick === idx}
                              data={item}
                              clickCart={clickCart}
                              clickLike={clickLike}
                              onClickDownload={onClickDownload}
                              buyStatus={item.buy_status}
                              downloadStatus={item.download_status}
                              isDesigner={item.isDesigner && userState.isLogin}
                              renderComponent={'search'}
                              page={page}
                              isOwn={item?.isOwn && userState.isLogin}
                            />
                          </>
                        );
                      })
                    ) : fetchedDataCount === 0 ? (
                      <>
                        <div className='product-list-nothing'>
                          <h2>
                            <strong>Sorry!</strong>
                          </h2>
                          <p>We couldn’t find any results.</p>
                          <a href='' className='txt-purple' onClick={goToStore}>
                            Go to store <i className='icon'></i>
                          </a>
                        </div>
                      </>
                    ) : (
                      Array.from({ length: loadingCount }).map((_, idx) => (
                        <ModelCard loading={true} key={idx} />
                      ))
                    )}
                  </CollectionGrid>
                </div>
              </div>
            </div>

            {pageCount > 0 && (
              <Pagenation
                count={pageCount}
                setPage={setPage}
                page={page}
                setPageNation={pageChange}
                viewCount={24}
                value={'searchList'}
              />
            )}
          </section>

          {selectedId && selectedModal && (
            <Selectefile
              setSelectedModal={setSelectedModal}
              id={selectedId}
              setSelectedId={setSelectedId}
              selectedName={selectedName}
              status={true}
            />
          )}
        </IndexContainer>
      </Webpage2StylesContainer>
    </Layout>
  );
}

export default Search;

const ImgWrap = styled.div`
  aspect-ratio: 1/1;
  img {
    width: 100%;
    height: 100%;
  }
`;

const IndexContainer = styled.div`
  .content-section {
    padding-top: 10px;
  }

  @media screen and (max-width: 830px) {
    .page-top.page-top-lg,
    .page-top.page-top-with-breadcrumb {
      height: 120px;
    }

    .page-title-sub {
      font-size: 24px;
      font-weight: 600;
      text-align: center;
    }

    .page-title02 {
      font-size: 14px;
    }

    .page-top .container-lg.container {
      gap: 5px;
    }
  }

  @media screen and (max-width: 700px) {
    margin-top: 20px !important;
  }
`;
