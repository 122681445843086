import { useContext, useEffect } from 'react';
import Layout from '../../component/layout/Layout';
import LangContext from '../../context/lang/lang';
import PrivacyEn from './component/PrivacyEn';
import PrivacyKo from './component/PrivacyKo';
import { MainContainer } from '../Term/Term';
import { useRecoilValue } from 'recoil';
import { isMotilink } from '../../atom/atom';
import { useNavigate } from 'react-router-dom';

const Privacy = () => {
  const navigate = useNavigate();
  const { action } = useContext(LangContext);
  const isMotilinkValue = useRecoilValue(isMotilink);

  return (
    <>
      {isMotilinkValue ? (
        <div>
          <MainContainer id='main'>
            <a onClick={() => navigate(-1)}>
              <img src='/assets/img/icon/close-button.png' className='btn-close' />
            </a>

            {action.isKo() ? <PrivacyKo /> : <PrivacyEn />}
          </MainContainer>
        </div>
      ) : (
        <Layout>
          <MainContainer id='main'>{action.isKo() ? <PrivacyKo /> : <PrivacyEn />}</MainContainer>
        </Layout>
      )}
    </>
  );
};

export default Privacy;
