import { useContext } from 'react';
import Layout from '../../component/layout/Layout';
import LangContext from '../../context/lang/lang';
import TermEn from './component/TermEn';
import TermKo from './component/TermKo';
import styled from 'styled-components';
import { isMotilink } from '../../atom/atom';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

const Term = () => {
  const navigate = useNavigate();
  const { action } = useContext(LangContext);
  const isMotilinkValue = useRecoilValue(isMotilink);
  return (
    <>
      {isMotilinkValue ? (
        <div>
          <MainContainer id='main'>
            <a onClick={() => navigate(-1)}>
              <img src='/assets/img/icon/close-button.png' className='btn-close' />
            </a>
            <div className='motilink-term'>{action.isKo() ? <TermKo /> : <TermEn />}</div>
          </MainContainer>
        </div>
      ) : (
        <Layout>
          <MainContainer id='main'>{action.isKo() ? <TermKo /> : <TermEn />}</MainContainer>
        </Layout>
      )}
    </>
  );
};
export default Term;

export const MainContainer = styled.div`
  background-image: url('/assets/img/members/bg.png');
  background-size: contain;
  background-size: 1920px auto;

  .motilink-term {
    .container {
      padding: 0px !important;
    }
  }

  .btn-close {
    width: 30px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 100;
  }
`;
