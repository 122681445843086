/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react';
import ModalContext from '../../../context/modal/modal';
import { baseUrl } from '../../../action/request';
import { useLocation } from 'react-router-dom';
import { SocialButton } from '../../layout/style/SignupStyle';
// import FacebookLogin from '@greatsumini/react-facebook-login';
// import { FacebookLoginClient } from '@greatsumini/react-facebook-login';

function Facebook() {
  const { action } = useContext(ModalContext);
  const location = useLocation();
  const currentPath = location.pathname;
  const currentSearch = location.search;
  // 페이스북 로그인 활성화 시에 주석 해제
  const onClickFacebook = async e => {
    e.preventDefault();
    e.stopPropagation();
    localStorage.setItem(
      'loginPath',
      !currentSearch ? currentPath : `${currentPath}${currentSearch}`
    );
    window.location.href = `${baseUrl}sns/facebook/callback`;
    // 로그인할 때 현재 위치 로컬스토리지에 저장
  };
  return (
    <SocialButton onClick={onClickFacebook} className='btn btn-full social-button'>
      {/* 페이스북 로그인 비활성화 시에 주석 해제 */}
      {/* <SocialButton
      className='btn btn-full social-button'
      onClick={e => {
        action.setIsLogin(false);
        action.setIsFacebookNotice(true);
      }}
    > */}
      <div>
        <img src='/assets/img/login/facebook.png' alt='Facebook' />
        <p>Facebook</p>
      </div>
    </SocialButton>

    // 페이스북 라이브러리로 유저정보 조회 테스트
    // <FacebookLogin
    //   className='btn btn-full social-button'
    //   style={{ padding: '0px', border: 'none', marginBottom: '16px' }}
    //   appId={process.env.REACT_APP_FB_APP_ID}
    //   onSuccess={res => {
    //     console.log('Facebook Login Success!');
    //     console.log('id: ', res);
    //     // 로그인할 때 현재 위치 로컬스토리지에 저장
    //     localStorage.setItem('loginPath', currentPath);
    //     navigate('/sns/facebook/redirect');
    //     // FacebookLoginClient.getLoginStatus(res => {
    //     //   console.log('facebook login status: ', res);
    //     // });

    //     // FacebookLoginClient.getProfile(res => {
    //     //   console.log('facebook getProfile: ', res.id, res.name, res.email);
    //     // });
    //   }}
    //   onFail={err => {
    //     console.log('Facebook Login Failed!');
    //     console.log('error: ', err);
    //     alert(err.status);
    //   }}
    //   onProfileSuccess={res => {
    //     console.log('Get Profile Success!');
    //     console.log('profile: ', res);
    //   }}
    //   children={
    //     <img
    //       src='/assets/img/login/facebook_new.png'
    //       alt='Facebook'
    //       style={{ width: '100%', cursor: 'pointer' }}
    //     />
    //   }
    // />
  );
}

export default Facebook;
