import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { baseUrl } from '../../../action/request';
import { SocialButton } from '../../layout/style/SignupStyle';

function Yahoo() {
  const location = useLocation();
  const currentPath = location.pathname;
  const currentSearch = location.search;

  const onClickYahoo = e => {
    e.preventDefault();
    e.stopPropagation();
    localStorage.setItem(
      'loginPath',
      !currentSearch ? currentPath : `${currentPath}${currentSearch}`
    );
    window.location.href = `${baseUrl}sns/yahoo/callback`;
    // 로그인할 때 현재 위치 로컬스토리지에 저장
  };
  return (
    <SocialButton className='btn btn-full social-button' onClick={onClickYahoo}>
      <div>
        <img src='/assets/img/login/yahoo.png' alt='Yahoo' />
        <p>Yahoo</p>
      </div>
    </SocialButton>
  );
}

export default Yahoo;
