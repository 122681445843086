import React, { useContext, useEffect } from 'react';
import Login from '../../component/layout/Login';
import ModalContext from '../../context/modal/modal';
import styled from 'styled-components';
import { isMotilink } from '../../atom/atom';
import { useRecoilState } from 'recoil';
import Signup from '../../component/layout/Signup';
import ForgotPassword from '../../component/layout/ForgotPassword';
import { logout } from '../../action/request';
import UserContext from '../../context/user/user';
import { ls } from '../../constant';

const AppdesignerLogin = () => {
  const { action: modalAction, state } = useContext(ModalContext);
  const [, setIsMotilink] = useRecoilState(isMotilink);

  const currentPath = window.location.pathname;
  const currentSearch = window.location.search;
  const { action: userAction } = useContext(UserContext);

  const onLogout = async e => {
    await logout(localStorage.getItem(ls.refreshToken));
    userAction.setInit();
    localStorage.removeItem(ls.accessToken);
    localStorage.removeItem(ls.refreshToken);
  };

  useEffect(() => {
    onLogout();
    modalAction.setIsLogin(true);
    setIsMotilink(true);
    localStorage.setItem(
      'loginPath',
      !currentSearch ? currentPath : `${currentPath}${currentSearch}`
    );
  }, []);

  return (
    <AppLogin>
      {state?.isLogin && <Login appValue={true} />}
      {state?.isForgotPassword && <ForgotPassword appValue={true} />}
      {state?.isSignup && <Signup appValue={true} />}
    </AppLogin>
  );
};

export default AppdesignerLogin;

const AppLogin = styled.div`
  .userAgent {
    margin-top: 30px;
  }
  @media screen and (max-width: 400px) {
    button {
      margin-bottom: 0px !important;
    }
  }
`;
