import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ImgStyled } from '../../../styles/ImgStyled';
import DiscountPrice from '../../section/DiscountPrice';
import { calculateDiscountPercentage } from '../../section/util';
import { DEFAULT_AVATAR } from '../../avatar/const';
import styled from 'styled-components';
import { ITEMS, REVIEW } from '../../../route/constant';
import { getMainThumb } from '../../../util/image';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  isMobileAtom,
  paginationAtom,
  scrollMoveAtom,
  reviewPageAtom,
  commentPageAtom,
} from '../../../atom/atom';
import ItemCardEtc from './ItemCardEtc';
import SkeletonCardMain from './SkeletonCardMain';
import { DESIGNERS } from '../../../route/constant';
import { removeSpecialCharactersAndSpaces } from '../../section/util';
import UserContext from '../../../context/user/user';

const ModelCard = ({
  data,
  value,
  page,
  clickLike,
  clickCart,
  handleClick,
  oneClick,
  twoClick,
  buyStatus,
  onClickDownload,
  downloadStatus,
  isDesigner,
  isOwn,
  renderComponent,
  loading,
}) => {
  const checkLocation = value === 'designerList' ? 'fromDesignerSection' : false;
  const { state: userState } = useContext(UserContext);
  const navigate = useNavigate();
  const reviewValue = value === 'review';
  const [, setScrollMove] = useRecoilState(scrollMoveAtom);
  const [, setPageNation] = useRecoilState(paginationAtom);
  const [, setReviewPage] = useRecoilState(reviewPageAtom);
  const [, setCommentPage] = useRecoilState(commentPageAtom);
  const [isHovering, setIsHovering] = useState(0);
  const priceOptionReward = data?.price_option === 'reward';
  const priceOptionFree = data?.price_option === 'free';
  const priceOptionPaid = data?.price_option === 'paid';
  const isProductId = data?.product_id === 'false';
  const isSafariOnIphone =
    /iPhone|iPad|iPod/.test(navigator.platform) &&
    /Safari/.test(navigator.userAgent) &&
    !/CriOS/.test(navigator.userAgent);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 700);
  useEffect(() => {
    if (oneClick) {
      setIsHovering(1);
    } else if (twoClick) {
      setIsHovering(2);
    } else {
      setIsHovering(0);
    }
  }, [oneClick, twoClick]);

  const onClickGoto = (e, path) => {
    e.preventDefault();
    e.stopPropagation();
    setReviewPage(1);
    setCommentPage(1);

    if (path !== 'reviewValue') {
      const productId = path.split('/')[path.split('/').length - 1];
      if (productId === 'null' || isProductId) return;
    }

    setPageNation(page);

    const navigateToPath = (path, location) => {
      setScrollMove(window.scrollY);

      if (renderComponent === 'search' && page) {
        navigate(path, { state: page });
      } else {
        navigate(path, location ? { state: { location } } : undefined);
      }
      window.scrollTo(0, 0);
    };

    localStorage.setItem('scrollDesigner', window.scrollY);

    if (reviewValue) {
      navigateToPath(REVIEW + '/' + data.review_oid);
      return;
    }

    const isDesignerList = value === 'designerList';
    const locationState = isDesignerList ? 'fromDesignerSection' : undefined;

    if (isMobile && isHovering === 1) {
      navigateToPath(path, locationState);
    } else if (!isMobile) {
      navigateToPath(path, locationState);
    }
  };

  const handleMouseEnter = () => {
    setIsHovering(1);
  };

  const handleMouseLeave = () => {
    setIsHovering(0);
  };

  const onClickDesignerPage = async (e, name, id, productid) => {
    e.stopPropagation();
    if (name === 'FAB365') {
      navigate(ITEMS + '/' + productid);
    } else {
      navigate(`${DESIGNERS}/${removeSpecialCharactersAndSpaces(name)}`, {
        state: { id: id, location: checkLocation },
      });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };
    window.addEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const clickHandler = e => {
      if (!e.target.classList.contains('imgWrap')) {
        setIsHovering(0);
      }
    };
    document.addEventListener('click', clickHandler);
    return () => {
      document.removeEventListener('click', clickHandler);
    };
  }, []);

  return (
    <>
      <Card className='shopItemCard' isHovering={isHovering}>
        {!data || loading ? (
          <SkeletonCardMain />
        ) : (
          <>
            <>
              {reviewValue ? (
                <Img onClick={e => onClickGoto(e, 'reviewValue')}>
                  <img src={getMainThumb(data)} alt='' />
                </Img>
              ) : (
                <Img
                  onClick={e => {
                    handleClick();
                    onClickGoto(e, ITEMS + '/' + data?.product_id, twoClick);
                  }}
                >
                  {isHovering > 0 && !isProductId && (
                    <div
                      className='designerProfile'
                      onClick={e =>
                        onClickDesignerPage(e, data?.studio_name, data?.id_studio, data?.product_id)
                      }
                      onMouseEnter={() => handleMouseEnter()}
                      onMouseLeave={() => handleMouseLeave()}
                    >
                      <ImgStyled
                        className='designerProfileImg'
                        src={data?.designer_img || DEFAULT_AVATAR}
                        alt=''
                      />
                    </div>
                  )}
                  {data?.product_listImg ? (
                    <div
                      className='imgWrap'
                      onMouseEnter={() => handleMouseEnter()}
                      onMouseLeave={() => handleMouseLeave()}
                    >
                      <ImgStyleHover1
                        alt=''
                        src={data?.product_listImg}
                        className='itemImg1'
                        isHovering={isHovering}
                      />
                      <ImgStyleHover2
                        alt=''
                        src={data?.product_listImg2 || data?.product_listImg}
                        isHovering={isHovering}
                      />

                      {!isHovering && (
                        <div className='normal'>
                          {/* 독점 표시*/}
                          {data.exclusive && !priceOptionReward && (
                            <div className='itemLabel exclusive' style={{ color: '#FAFAFA' }}>
                              Fab<span style={{ color: '#28CE40' }}>365</span> Only
                            </div>
                          )}
                          {priceOptionReward && (
                            <>
                              <div className='itemLabel reward'>Reward</div>
                              {buyStatus !== 'y' && (
                                <div className='ticket'>
                                  <div className='hiddenTxt'>Ticket</div>
                                  <div className='amount'>{data?.reward_qty}</div>
                                </div>
                              )}
                            </>
                          )}
                          {priceOptionFree && (
                            <>
                              <FreeLabel>
                                <div className='ribbon'>
                                  <p>FREE</p>
                                </div>
                              </FreeLabel>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <ImgStyled
                      src='/assets/img/2.0WebImg/content/coming_soon.jpg'
                      alt='FAB365 COMING SOON'
                    />
                  )}

                  {data?.product_listImg && isHovering ? (
                    <div className='hover'>
                      <button
                        tabIndex='0'
                        type='button'
                        className={`buttonFavorite ${data?.my_like && 'active'}`}
                        title='add this item to the favorite list'
                        onClick={e => clickLike(e, data.product_id, data?.id_studio)}
                        style={{
                          bottom:
                            data?.price_option === 'paid' ||
                            buyStatus === 'y' ||
                            downloadStatus === 'y' ||
                            isDesigner ||
                            isOwn
                              ? '60px'
                              : '20px',
                        }}
                        onMouseEnter={() => setIsHovering(1)}
                        onMouseLeave={() => setIsHovering(0)}
                      />
                      {userState.isLogin &&
                        ((priceOptionReward && buyStatus === 'y') ||
                        (priceOptionFree && downloadStatus === 'y') ||
                        isDesigner ||
                        isOwn ? (
                          <IconButton
                            className='test22'
                            background={'/assets/img/icon/hover_download.png'}
                            onMouseEnter={() => setIsHovering(1)}
                            onMouseLeave={() => setIsHovering(0)}
                            onClick={e => onClickDownload(e, data?.product_id, data?.product_name)}
                          />
                        ) : null)}

                      {priceOptionPaid && (
                        <>
                          {buyStatus !== 'y' && !isDesigner ? (
                            <button
                              tabIndex='0'
                              type='button'
                              className={`buttonCart ${data.in_cart && 'active'}`}
                              title='app this item to the cart'
                              onClick={e =>
                                clickCart(
                                  e,
                                  data.finalPrice,
                                  data.product_id,
                                  data.in_cart,
                                  data.id_studio
                                )
                              }
                              onMouseEnter={() => setIsHovering(1)}
                              onMouseLeave={() => setIsHovering(0)}
                            />
                          ) : (
                            <IconButton
                              className='test22'
                              background={'/assets/img/icon/hover_download.png'}
                              onMouseEnter={() => setIsHovering(1)}
                              onMouseLeave={() => setIsHovering(0)}
                              onClick={e =>
                                onClickDownload(e, data?.product_id, data?.product_name)
                              }
                            />
                          )}
                        </>
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                </Img>
              )}

              {reviewValue ? (
                <Info
                  onClick={e => onClickGoto(e, ITEMS + '/' + data.product_id)}
                  renderComponent={renderComponent}
                >
                  <div style={{ paddingBottom: 10 }}>
                    <Product className='name'>{data.user_name}</Product>
                    <Product>{data.subject}</Product>
                  </div>
                </Info>
              ) : (
                <Info renderComponent={renderComponent}>
                  <div>
                    <Product
                      className='name'
                      onClick={e =>
                        onClickDesignerPage(e, data?.studio_name, data?.id_studio, data?.product_id)
                      }
                    >
                      {data.studio_name}
                    </Product>
                    <Product onClick={e => onClickGoto(e, ITEMS + '/' + data.product_id)}>
                      {data?.product_name}
                    </Product>
                  </div>

                  {reviewValue ? (
                    ''
                  ) : (
                    <div
                      className='priceRow'
                      onClick={e => onClickGoto(e, ITEMS + '/' + data.product_id)}
                    >
                      <>
                        <PriceOption className='price' buyStatus={buyStatus}>
                          {priceOptionPaid ? (
                            data.finalPrice && data.originPrice && data.discountRate ? (
                              buyStatus === 'y' || (userState.isLogin && isDesigner) ? (
                                'Purchased'
                              ) : (
                                <DiscountPrice
                                  finalPrice={data.finalPrice}
                                  originPrice={data.originPrice}
                                  discountRate={data.discountRate}
                                />
                              )
                            ) : null
                          ) : priceOptionReward ? (
                            buyStatus === 'y' || (userState.isLogin && isDesigner) || isOwn ? (
                              'Owned'
                            ) : (
                              'Free'
                            )
                          ) : priceOptionFree ? (
                            downloadStatus === 'y' || (userState.isLogin && isDesigner) ? (
                              'Owned'
                            ) : (
                              'Free'
                            )
                          ) : null}
                        </PriceOption>

                        {data?.isDiscount && (
                          <>
                            <div className='originalPrice'>$ {data.originPrice}</div>
                            <div className='discount'>
                              {calculateDiscountPercentage(data.originPrice, data.finalPrice)}%
                            </div>
                          </>
                        )}
                      </>
                    </div>
                  )}
                </Info>
              )}
            </>
            {!isProductId && <ItemCardEtc data={data} reviewValue={reviewValue} />}
          </>
        )}
      </Card>
    </>
  );
};

export default ModelCard;

const Card = styled.div`
  width: 100%;
  margin-bottom: 30px;
  position: relative;

  .imgWrap:hover img {
    opacity: 1 !important;
  }

  .itemLabel {
    position: absolute;
    left: 0;
    top: 0;
    background: #282432;
    height: 34px;
    line-height: 34px;
    text-align: left;
    padding: 0 11px 0 16px;
    z-index: 10;
    color: #fff !important;
    font-size: 14px;
    font-weight: 600;
  }

  .itemLabel.reward {
    background: #f7f15b;
    color: #151515 !important;
  }

  .itemLabel.reward:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: -20px;
    border-top: 17px solid #f7f15b;
    border-left: 10px solid #f7f15b;
    border-right: 10px solid transparent;
    border-bottom: 17px solid transparent;
  }

  .ticket {
    position: absolute;
    left: 16px;
    bottom: 16px;
    width: 56px;
    height: 28px;
    background: url('/assets/img/2.0WebImg/ui/icon_ticket.png') center no-repeat;
    width: 56px;
    height: 28px;
    background-size: 56px 28px;
  }

  .ticket .amount {
    display: block;
    position: absolute;
    width: 26px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    background: #f7f15b;
    color: #151515;
    border-radius: 13px;
    color: #151515;
    font-size: 14px;
    font-weight: 500;
    top: -13px;
    right: -13px;
  }

  .buttonFavorite.active {
    background: #fff url('/assets/img/2.0WebImg/ui/favorite_on.svg') center no-repeat !important;
    background-size: 24px 24px !important;
  }

  .buttonCart.active {
    background: #fff url('/assets/img/2.0WebImg/ui/shopping_cart_on.svg') center no-repeat !important;
    background-size: 24px 24px !important;
  }

  @media screen and (max-width: 480px) {
    .hover .buttonFavorite {
      width: 35px;
      height: 35px;
    }

    .hover .buttonCart {
      width: 35px;
      height: 35px;
    }
  }

  .designerProfile {
    width: 30px;
    height: 30px;
    position: absolute;
    z-index: 12;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background-color: #e8e0e0;
    border-radius: 100px;
    opacity: ${props => (props.isHovering === 1 ? 1 : 0)};
  }

  .designerProfileImg {
    width: 100%;
    height: 100%;
    border-radius: 100px;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 100%;

  .name {
    color: #171717;
    font-size: 14px;
    font-weight: 500;
    max-width: 300px;
    margin-bottom: ${props => {
      switch (props.renderComponent) {
        case 'collectionItemList':
        case 'category':
        case 'setItem':
        case 'search':
          return '0px';
        default:
          return '5px';
      }
    }};
  }

  @media screen and (max-width: 480px) {
    .name {
      color: #171717;
      font-size: 14px;
    }
    .product-name {
      font-size: 14px;
    }
  }
`;

const Img = styled.div`
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
  img {
    pointer-events: none;
    cursor: pointer;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
    aspect-ratio: 1/1;
    background-color: #e8e0e0;
  }

  .hover .buttonFavorite {
    display: block;
    position: absolute;
    z-index: 11;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background: #fff url('/assets/img/2.0WebImg/ui/favorite2.svg') center no-repeat;
    background-size: 24px 24px;
    right: 12px;
    bottom: 10px;
  }

  .hover .buttonCart {
    display: block;
    position: absolute;
    z-index: 11;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background: #fff url('/assets/img/2.0WebImg/ui/shopping_cart.svg') center no-repeat;
    background-size: 24px 24px;
    right: 12px;
    bottom: 12px;
  }
`;

const Product = styled.p`
  width: 100%;
  color: #686868;
  font-size: 18px;
  font-weight: 300;

  text-overflow: ellipsis;
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const Price = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

const ImgStyleHover1 = styled.img`
  /* opacity: ${props => (props.isHovering ? 1 : 0)}; */
`;

const ImgStyleHover2 = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${props => (props.isHovering === 1 ? 1 : 0)};
`;

const IconButton = styled.button`
  width: 40px !important;
  height: 40px !important;
  z-index: 11;
  border-radius: 8px !important;
  background: ${props => `url(${props.background}) center / 100% no-repeat`} !important;
  position: absolute;
  right: 12px;
  bottom: 12px;
`;

const PriceOption = styled.div`
  font-family: 'Outfit', sans-serif !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  margin-bottom: 8px !important;
`;

const FreeLabel = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
  width: 100%;
  background: none !important;
  height: 86px !important;
  .ribbon {
    position: absolute;
    top: 27px;
    right: -101px;
    width: 247px;
    background-color: #33b630;
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 1px 0px;
    transform: rotate(35deg);
    font-size: 20px;
    font-weight: bold;

    p {
      position: relative;
      right: 24px;
      font-weight: 600 !important;
    }
  }
`;
